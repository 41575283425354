// Place for different placeholders and mixins used over all website
@mixin place-in-grid($top, $left, $right: $left + 1, $bottom: $top + 1) {
    grid-column-start: $left;
    grid-column-end: $right;
    grid-row-start: $top;
    grid-row-end: $bottom;
}

%full-size-image-wrapper {
    position: relative;
    display: block;
    overflow: hidden;
}

%full-size-image {
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    size: 100%;
    object-fit: cover;
}

%visually-hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    opacity: 0;
    z-index: -1;
}

%visually-unhidden {
    position: relative;
    width: auto;
    height: auto;
    margin: 0;
    opacity: 1;
    z-index: 0;
}

%display-flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

%footer-contacts-caption {
    display: block;
    font-size: toRem(14px);
    line-height: 1.1;
    opacity: 0.6;
}
