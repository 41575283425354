.TimeWork {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    position: relative;

    font-size: toRem(14px);
    font-weight: $font-weight-medium;
    line-height: 1.28;

    &--bold {
        font-weight: $font-weight-bold;
    }

    &__icon {
        margin-right: 18px;
    }

    &__main {
        margin-right: 16px;
    }
}
