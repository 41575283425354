.Content {
    $color-list-bullet: $color-dark-pink;

    font-size: toRem(16px);

    & > * {
        &:first-child {
            html:root & {
                margin-top: 0;
            }
        }
    }

    img {
        // sass-lint:disable-block no-important
        height: auto !important;
        margin-bottom: 28px;
    }

    p {
        margin-bottom: 16px;

        @include vw-medium-down {
            margin-bottom: 12px;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    a {
        text-decoration: underline;
    }

    em {
        color: unset;
    }

    ul,
    ol {
        li {
            position: relative;

            margin-y: 8px;
            padding-left: 22px;

            @include vw-small-down {
                margin: 8px 0;
            }

            &::before {
                position: absolute;
                left: 0;
            }

            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }

    ul,
    ol,
    blockquote {
        margin: 24px 0;

        @include vw-small-down {
            margin: 16px 0;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    ul {
        --unordered-list-bullet-color: #{$color-list-bullet};
        --unordered-list-bullet-border-color: #{$color-list-bullet};

        li {
            &::before {
                background-color: var(--unordered-list-bullet-color);
                border: 1px solid var(--unordered-list-bullet-border-color);
                border-radius: 50%;
                content: "";
                size: 7px;
                top: 7px;
            }

            > ul {
                --unordered-list-bullet-color: white;
                --unordered-list-bullet-border-color: #{lighten($color-list-bullet, 5%)};

                margin-left: 12px;
            }
        }
    }

    ol {
        counter-reset: list-counter;

        li {
            counter-increment: list-counter;

            &::before {
                content: counter(list-counter) ".";

                top: 0;
                font-size: inherit;
                font-weight: $font-weight-medium;
            }
        }
    }
}
