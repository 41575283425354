%link {
    line-height: 1.2;
    transition-property: color, opacity;
    transition-duration: 0.2s;
    transition-timing-function: ease-in-out;
    color: var(--link-color);

    &:hover,
    &:focus,
    &:active {
        text-decoration: none;
        color: var(--link-color);
        opacity: 0.6;
    }

    &-xs {
        font-size: toRem(12px);
    }

    &-s {
        font-size: toRem(14px);
    }

    &-m {
        font-size: toRem(16px);
    }

    &-l {
        font-size: toRem(20px);
        line-height: 1.4;
    }

    &-xl {
        font-size: toRem(24px);
    }

    &-regular {
        font-weight: $font-weight-regular;
    }

    &-medium {
        font-weight: $font-weight-medium;
    }

    &-bold {
        font-weight: $font-weight-bold;
    }

    &-default {
        --link-color: #{$color-black};
    }

    &-white {
        --link-color: #{$color-white};
    }

    &-new-green {
        --link-color: #{$color-new-green};
    }

    &-orange {
        --link-color: #{$color-orange};
    }

    &-dark-pink {
        --link-color: #{$color-dark-pink};
    }

    &-dark-purple {
        --link-color: #{$color-dark-purple};
    }

    &-hover-new-green {
        &:hover {
            --link-color: #{$color-new-green};
        }
    }

    &-underlined {
        text-decoration: underline;

        &:hover {
            text-decoration: underline;
        }
    }

    &-fancy {
        position: relative;
        display: inline-block;

        &::before {
            content: "";
            position: absolute;
            bottom: 1px;
            display: block;
            width: 100%;
            height: 1px;
            background-color: var(--link-color);
            transform: scaleX(0);
            transform-origin: right;
            transition-duration: 0.3s;
            transition-property: transform, background-color;
        }

        &:hover {
            &::before {
                @media (hover: hover) {
                    transform: scaleX(1);
                    transform-origin: left;
                }
            }
        }
    }
}
