// It's like «normalize» or «reset»
// Some ideas here got from https://github.com/hankchizljaw/modern-css-reset
// Other code is made in FEIP

// Box sizing rules
*,
*::before,
*::after {
	box-sizing: border-box;
}

ul,
ol {
	// Remove default {padding}
	padding: 0;
	// Remove list styles on <ul> and <ol> with a [class] attribute
	list-style: none;
}

// Remove default margin
body,
h1,
h2,
h3,
h4,
p,
ul,
ol,
li,
figure,
figcaption,
blockquote,
dl,
dd {
	margin: 0;
}

// Enable smooth scroll
html {
	font-size: 100%;
	-webkit-tap-highlight-color: transparent;
	scroll-behavior: smooth;
}

// Set core <body> defaults
body {
	min-height: 100vh;
	text-rendering: optimizeSpeed;
	line-height: 1.5;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

// <a> that don't have a [class] get default styles
a:not([class]) {
	text-decoration-skip-ink: auto;
}

// Make images easier to work with
img {
	max-width: 100%;
	display: block;
}

// Inherit fonts for inputs and buttons
input,
button,
textarea,
select {
	font: inherit;
}

// Remove all {animation}s and {transition}s for people that prefer not to see them
@media (prefers-reduced-motion: reduce) {
* {
	// sass-lint:disable-block no-important
	animation-duration: 0.01ms !important;
	animation-iteration-count: 1 !important;
	transition-duration: 0.01ms !important;
	scroll-behavior: auto !important;
}
}

// Only vertical resizing of text area
textarea {
	resize: vertical;
}

// Limit <iframe> width
iframe {
	max-width: 100%;
}

// Make <fieldset> work good with {display:flex}
fieldset {
	min-width: 0;
}

// Make <th> only a semantic element
th {
	font-weight: inherit;
	text-align: inherit;
}
