.SiteNav {
    $p: &;

    &__listItem {
        @include vw-small-down {
            text-align: center;
        }
    }

    &__listItem:not(:last-child) {
        margin-bottom: 24px;
    }

    &__item {
        @extend %link, %link-m, %link-medium, %link-white;

        &--active {
            pointer-events: none;
        }
    }
}

.LinkBack {
    $block: &;

    position: relative;
    transition: color 0.2s ease-in-out;

    &__link {
        @extend %link, %link-s, %link-bold, %link-default;

        &:hover,
        &:focus,
        &:active {
            color: $color-dark-pink;
            opacity: 1;

            & #{$block}__svg {
                stroke: $color-dark-pink;
            }
        }
    }

    &__svg {
        transition: stroke 0.2s ease-in-out;
        margin-right: 16px;
        top: -2px;
        position: relative;
    }
}
