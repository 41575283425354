.PolicyList {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    opacity: 0.8;

    &__item {
        &:first-child {
            margin-right: 16px;
        }

        &:not(:last-child) {
            margin-bottom: 8px;
        }
    }

    &__value {
        @extend %link, %link-white, %link-regular, %link-xs, %link-underlined;
    }
}
