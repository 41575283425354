.VideoSection {
    &__container {
        @include vw-small-down {
            padding: 0;
        }
    }
}

.VideoPlayer {
    $block: &;
    $player-z-index: 1;
    $preview-z-index: $player-z-index - 1;
    $play-button-z-index: $preview-z-index + 2;

    position: relative;
    width: 100%;
    max-width: 1200px;
    cursor: pointer;
    outline: none;
    padding-bottom: aspect-ratio(16, 9);
    box-shadow: 0 30px 40px -30px rgba(27, 0, 26, 0.7);
    border-radius: 20px;

    @include vw-small-down {
        border-radius: 0;
    }

    &--loaded {
        #{$block}__picture,
        #{$block}__playButton {
            opacity: 0;
            pointer-events: none;
        }
    }

    &--loading {
        #{$block}__playIcon {
            display: none;
        }

        #{$block}__spinner {
            display: block;
        }
    }

    &__preview {
        transition: opacity 0.25s ease-in-out;
        border-radius: 20px;

        @include vw-small-down {
            border-radius: 0;
        }
    }

    &__preview,
    &__player {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    &__player {
        z-index: $player-z-index;
        border-radius: 20px;
        background-color: rgba(35, 31, 32, 0.5);

        @include vw-small-down {
            border-radius: 0;
        }

        &:hover #{$block}__playIcon::before {
            background-color: rgba($color-white, 0.4);
        }

        &:active #{$block}__playIcon::before {
            background-color: rgba($color-white, 0.4);
        }

        &:active #{$block}__playIcon::after {
            border-left-color: #0c7973;
        }
    }

    &__playButton {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: $play-button-z-index;
        width: 160px;
        height: 160px;
        padding: 0;
        background-color: rgba(black, 0);
        border: none;
        border-radius: 50%;
        pointer-events: none;
        outline: none;
        transition: opacity 0.25s;

        @include vw-xxsmall-down {
            width: 120px;
            height: 120px;
        }

        &::before,
        &::after {
            content: "";
            display: block;
            position: absolute;
        }

        &::before {
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            background-color: $color-new-green;
            filter: blur(30px);
            z-index: -1;

            @include vw-xxsmall-down {
                width: 80%;
                height: 80%;
                top: 10%;
                left: 10%;
            }
        }

        &::after {
            width: 60%;
            height: 60%;
            top: 20%;
            left: 20%;
            border: 1px dashed $color-white;
            border-radius: 50%;
            background-color: rgba(black, 0);
        }
    }

    &__playIcon {
        transition: transform 0.35s ease-out;
        display: block;
        position: relative;
        width: 80px;
        height: 80px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 3;

        &::before,
        &::after {
            content: "";
            display: block;
            position: absolute;
        }

        &::before {
            width: 80px;
            height: 80px;
            border: 1px solid $color-white;
            border-radius: 50%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        &::after {
            width: 0;
            height: 0;
            border-top: 14px solid transparent;
            border-bottom: 14px solid transparent;
            border-left: 24px solid $color-white;
            top: 50%;
            left: 55%;
            transform: translate(-50%, -50%);
        }
    }

    &__spinner {
        display: none;
        color: white;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 3;
        position: absolute;
    }

    &__link {
        @extend %link;

        &:focus #{$block}__playIcon::before {
            background-color: #0b635e;
        }
    }
}
