.PhonesList {
    $block: &;

    &--footer {
        #{$block}__value {
            @extend %link-white;
        }
    }

    &--contacts {
        #{$block}__value {
            @extend %link-default;
        }
    }

    &__item:not(:last-child) {
        margin-bottom: 8px;
    }

    &__value {
        @extend %link, %link-regular, %link-l;
    }

    &__caption {
        @extend %footer-contacts-caption;
    }
}
