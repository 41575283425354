.PartnersList {
    $block: &;

    &--tooltip,
    &--footer {
        #{$block}__item {
            font-weight: $font-weight-medium;
        }
    }

    &--footer {
        opacity: 0.7;
    }

    &__item {
        font-size: toRem(14px);
        line-height: 1.28;

        max-width: 320px;

        @include vw-small-down {
            max-width: 100%;
        }

        &:not(:last-child) {
            margin-bottom: 8px;
        }
    }
}
