%_heading-base {
    font-weight: $font-weight-bold;
}

%heading-small {
    @extend %_heading-base !optional;

    font-size: toRem($font-size-heading-small);

    @include vw-medium-down {
        font-size: toRem(18px);
    }
}

%heading-medium {
    @extend %_heading-base !optional;

    font-size: toRem($font-size-heading-medium);

    @include vw-small-down {
        font-size: toRem(24px);
    }
}

%heading-large {
    @extend %_heading-base !optional;

    font-size: toRem($font-size-heading-large);

    @include vw-medium-down {
        font-size: toRem(44px);
    }

    @include vw-small-down {
        font-size: toRem(28px);
    }
}
