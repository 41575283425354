.SiteFooter {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-content: space-between;
    padding-y: 48px;
    color: $color-white;
    column-gap: 70px;
    flex-wrap: wrap;

    @include vw-small-down {
        grid-template-columns: 1fr;
        padding-y: 24px;
    }

    &__sdcLogoWrapper {
        @include place-in-grid(1, 1);

        margin-bottom: 40px;
    }

    &__sdcLogo {
        width: 165px;
        height: 64px;
    }

    &__address {
        @include place-in-grid(2, 1);

        margin-bottom: 18px;
        max-width: 360px;
        font-size: toRem(20px);
        line-height: 1.4;

        @include vw-small-down {
            @include place-in-grid(3, 1);

            max-width: 180px;
            margin-bottom: 34px;
        }
    }

    &__timeWork {
        @include place-in-grid(3, 1);

        margin-bottom: 16px;

        @include vw-small-down {
            @include place-in-grid(4, 1);
        }
    }

    &__partnersList {
        @include place-in-grid(4, 1);

        margin-bottom: 40px;

        @include vw-small-down {
            @include place-in-grid(5, 1);
        }
    }

    &__phonesList {
        @include place-in-grid(5, 1);

        margin-bottom: 14px;

        @include vw-small-down {
            @include place-in-grid(7, 1);
        }
    }

    &__emailsList {
        @include place-in-grid(6, 1);

        margin-bottom: 48px;

        @include vw-small-down {
            @include place-in-grid(8, 1);

            margin-bottom: 40px;
        }
    }

    &__proposalsList {
        @include place-in-grid(1, 2);

        margin-top: 16px;
        margin-bottom: 40px;

        @include vw-large-down {
            @include place-in-grid(1, 2, $bottom: 3);
        }

        @include vw-small-down {
            display: none;
        }
    }

    &__container {
        @include place-in-grid(2, 2, $bottom: 9);

        display: flex;
        flex-wrap: wrap;
        flex-direction: column;

        @include vw-large-down {
            @include place-in-grid(3, 2, $bottom: 9);
        }

        @include vw-small-down {
            @include place-in-grid(9, 1);

            margin-bottom: 24px;
        }
    }

    &__nav {
        margin-bottom: auto;

        @include vw-small-down {
            display: none;
        }
    }

    &__socialsList {
        margin-top: 30px;

        @include vw-medium-down {
            margin-bottom: 70px;
        }

        @include vw-small-down {
            margin: 0;
            align-self: flex-start;
        }
    }

    &__copyright {
        @include place-in-grid(7, 1);

        display: block;
        margin-bottom: 16px;
        opacity: 0.6;
        font-size: toRem(14px);
        line-height: 1.28;

        @include vw-small-down {
            @include place-in-grid(10, 1);

            margin-bottom: 8px;
        }
    }

    &__policyList {
        @include place-in-grid(8, 1);

        font-size: toRem(14px);
        line-height: 1.25;

        @include vw-small-down {
            @include place-in-grid(11, 1);

            margin-bottom: 16px;
            max-width: 300px;
        }
    }

    &__feipLogo {
        @include place-in-grid(7, 2, $bottom: 9);

        margin-left: auto;
        align-self: flex-end;
        color: $color-white;
        opacity: 0.3;
        transition: opacity 0.25s ease-in-out;

        @include vw-medium-down {
            justify-self: flex-start;
        }

        @include vw-small-down {
            @include place-in-grid(12, 1);

            margin-left: 0;
        }

        &:hover {
            opacity: 1; // FEIP logo
            color: $color-white;
        }

        svg {
            fill: currentColor; // Developed by
        }
    }
}
