.SocialsList {
    $block: &;

    display: flex;

    &--footer {
        #{$block}__item {
            filter: drop-shadow(0px 8px 12px rgba(0, 0, 0, 0.15));
        }
    }

    &--contacts,
    &--shop {
        #{$block}__item {
            &:not(:last-child) {
                margin-right: 24px;
            }
        }

        #{$block}__icon {
            fill: $color-black;
            transition: fill 0.2s ease-in-out;

            &:hover {
                fill: $color-new-green;
                opacity: 1;
            }
        }

        #{$block}__link {
            &:focus #{$block}__icon {
                fill: $color-new-green;
            }
        }
    }

    &__item {
        size: 32px;

        &:not(:last-child) {
            margin-right: 32px;
        }

        &:last-child {
            @include vw-xsmall-down {
                margin-right: 0;
            }
        }
    }

    &__icon {
        transition: opacity 0.2s ease-in-out;

        &:hover {
            opacity: 0.6;
        }
    }
}
