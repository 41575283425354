.ErrorPage {
    @extend %large-padding-page;

    padding-bottom: 112px;
    width: 100%;

    @include vw-medium-down {
        padding-bottom: 72px;
    }

    @include vw-small-down {
        padding-bottom: 56px;
    }

    &__content {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__title {
        @extend %heading-medium;

        margin-bottom: 48px;

        @include vw-small-down {
            margin-bottom: 24px;
        }
    }

    &__description {
        margin-bottom: 56px;

        @include vw-small-down {
            margin-bottom: 32px;
        }
    }

    &__code {
        font-weight: $font-weight-bold;
        font-size: toRem(144px);
        line-height: 100%;
        color: white;
        text-shadow: 0px 4px 40px rgba($color-dark-purple, 0.25);
        margin-bottom: 8px;
        text-align: center;

        @include vw-small-down {
            width: 100%;
            font-size: toRem(100px);
            margin-bottom: 16px;
        }
    }

    &__homeButton {
        @extend .uk-button, .uk-button-large, .uk-button-rounded, .uk-button-dark-pink, .uk-button-rimmed;

        @include vw-small-down {
            width: 100%;
        }

        @include vw-xxsmall-down {
            font-size: toRem(18px);
        }
    }
}
