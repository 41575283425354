.ProposalsList {
    $block: &;

    display: grid;
    grid-template-columns: repeat(2, max-content);

    @include vw-medium-down {
        grid-template-columns: 1fr;
    }

    &--footer {
        grid-gap: 24px;

        #{$block}__button {
            @extend .uk-button-border-white;
        }
    }

    &--contacts {
        grid-gap: 16px;

        #{$block}__button {
            @extend .uk-button-green, .uk-button-border-transparent-green;

            padding-x: 20px;

            &:hover {
                background-color: rgba(#d9f4f2, 0.5);
            }

            &:focus {
                background-color: rgba(#eeeeee, 0.5);
            }

            &:active {
                background-color: rgba(#d1e9e7, 0.5);
            }
        }
    }

    &__button {
        @extend .uk-button, .uk-button-transparent, .uk-button-rounded, .uk-button-border-dashed;

        &:hover,
        &:focus,
        &:active {
            background-color: rgba($color-white, 0.2);
        }
    }
}
