// Base
@mixin hook-base-link {
    transition: color 0.25s ease-in-out;
}

// Buttons
@mixin hook-button {
    font-weight: $font-weight-bold;
    transition: background-color 0.25s ease-in-out, color 0.2s ease-in-out;
}

@mixin hook-button-misc {
    .uk-button {
        &-round {
            width: toRem(48px);
            height: toRem(48px);
            line-height: toRem(50px);
            border-radius: 50%;
            padding: 0;
        }

        &-rounded {
            border-radius: 500px;
        }

        &-transparent {
            background-color: transparent;

            &:hover {
                background-color: rgba($color-white, 0.2);
            }
        }

        &-green {
            color: $color-new-green;
        }

        &-light-pink {
            background-color: $color-light-pink;
            color: $color-white;

            &:hover {
                color: $color-white;
            }
        }

        &-greenish {
            color: $color-dark-purple;
            background-color: rgba($color-new-green, 0.05);

            &:hover {
                background-color: rgba($color-new-green, 0.15);
                color: rgba($color-dark-purple, 0.6);
            }

            &:focus {
                background-color: #f6f6f6;
            }

            &:active {
                background-color: rgba($color-new-green, 0.2);
                color: rgba($color-dark-purple, 0.4);
            }
        }

        &-grayish {
            background-color: rgba($color-black, 0.07);
        }

        &-white {
            background-color: $color-white;
            color: $color-dark-purple;

            &:hover {
                color: $color-dark-purple;
                background-color: #d5cad5;
            }

            &:active {
                background-color: #9f859e;
            }
        }

        &-rimmed {
            position: relative;

            &::after {
                content: "";
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                size: calc(100% + 16px);
                border: 1px dashed $color-light-pink;
                border-radius: 500px;
            }
        }

        &-font {
            &-purple {
                color: $color-dark-purple;
            }
        }

        &-dark-pink {
            background-color: $color-dark-pink;
            color: $color-white;
            transition: box-shadow 0.2s ease-in-out, background-color 0.2s ease-in-out;

            &:hover {
                background-color: #981240;
            }

            &:focus {
                background-color: $color-dark-pink;
                box-shadow: inset 0 0 10px #761536;
            }

            &:active {
                background-color: #761536;
            }

            &:hover,
            &:focus,
            &:active {
                color: $color-white;
            }
        }

        &-purple {
            background-color: $color-dark-purple;
            color: $color-white;

            &:hover {
                color: $color-white;
            }
        }

        &-shadow {
            box-shadow: 0 4px 20px rgba(71, 16, 69, 0.05);
            transition: box-shadow 0.25s ease-in-out;

            @media (hover: hover) {
                &:hover,
                &:focus {
                    box-shadow: -1px 2px 10px rgba(0, 0, 0, 0.1);
                }
            }
        }

        &-nav {
            &:focus {
                background-color: #e9e9e9;
            }

            &:hover {
                background-color: rgba($color-dark-purple, 0.06);
            }

            &:active {
                background-color: rgba($color-dark-purple, 0.15);
            }

            &-prev {
                svg {
                    transform: translateX(-1px);
                }
            }

            &-next {
                svg {
                    transform: translateX(1px);
                }
            }
        }
    }

    .uk-button-border {
        &-dashed {
            border: 1px dashed $color-dark-purple;
        }

        &-green {
            border-color: $color-new-green;
        }

        &-transparent-green {
            border-color: rgba($color-new-green, 0.5);
        }

        &-white {
            border-color: $color-white;
        }

        &-pink {
            border-color: $color-dark-pink;
        }

        &-dark-purple {
            border-color: transparentize($color-dark-purple, 0.7);
        }
    }
}

@mixin hook-search-input() {
    border-radius: 5px;
    font-size: toRem(14px);
}

@mixin hook-offcanvas-misc() {
    @include vw-xsmall-up {
        .uk-offcanvas-bar {
            left: (-$offcanvas-bar-width-s);
            width: $offcanvas-bar-width-s;
            padding: $offcanvas-bar-padding-vertical-m $offcanvas-bar-padding-horizontal-m;
        }
    }

    @media (min-width: $breakpoint-small) {
        .uk-offcanvas-flip .uk-offcanvas-bar {
            right: (-$offcanvas-bar-width-s);
        }
    }
}

// Modal
@mixin hook-modal-body {
    @include vw-small-down {
        padding: 25px;
    }
}

@mixin hook-search-default-input {
    transition: background-color 0.2s ease-in-out;

    &::placeholder {
        transition: color 0.2s ease-in-out;
    }

    &:hover {
        @include media-no-touch {
            background-color: #00635d;

            &::placeholder {
                color: $color-white;
            }
        }
    }
}

// Form
@mixin hook-form {
    border: 1px solid rgba($color-dark-purple, 0.2);
    border-radius: 5px;
}
