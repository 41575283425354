$pagination-element-size: 48px;
$pagination-element-size-s: 56px;

.ListPagination {
    $block: &;

    padding-top: 64px;
    display: flex;
    width: 100%;
    margin-bottom: 0;

    @include vw-small-down {
        justify-content: center;
    }

    @include vw-medium-down {
        padding-top: 64px;
    }

    @include vw-xsmall-down {
        padding-top: 56px;
    }

    &--left {
        justify-content: flex-start;
    }

    &--right {
        justify-content: flex-end;
    }

    &--center {
        justify-content: center;
    }

    &__mobileSelect {
        @extend .uk-button;

        line-height: 56px;
        padding: 0 21px 0 0;
        appearance: none;
        text-align: center;
        text-align-last: center;
        outline: none;
        background-color: white;

        @include vw-xxsmall-down {
            margin: 0;
        }
    }

    &__link {
        @extend %link, %link-s, %link-bold, %display-flex-center;

        size: 100%;
        padding-top: 3px;
        line-height: 1.28;
        color: inherit;
        border-radius: 50%;
        transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;

        &:hover,
        &:focus,
        &:active {
            opacity: 1;
        }

        &:focus {
            background-color: #e9e9e9;
        }

        &:active {
            background-color: rgba($color-dark-purple, 0.5);
            color: $color-white;
        }
    }

    &__item {
        @extend .uk-button, .uk-button-round, .uk-button-font-purple, %link-dark-purple, %display-flex-center;

        margin: 0 4px;
        height: $pagination-element-size;
        min-width: $pagination-element-size;

        @include vw-xsmall-down {
            height: $pagination-element-size-s;
            min-width: $pagination-element-size-s;
        }

        &:first-child {
            margin-right: 48px;

            @include vw-xsmall-down {
                margin-right: 24px;
            }
        }

        &:last-child {
            margin-left: 48px;

            @include vw-xsmall-down {
                margin-left: 24px;
            }
        }

        &--desktop {
            @extend .uk-button-shadow, .uk-button-white;

            transition: background-color 0.2s ease-in-out;

            @include vw-small-down {
                display: none;
            }
        }

        &--mobile {
            width: 115px;
            position: relative;

            @include vw-small-up {
                display: none;
            }

            &::after {
                $borderWidth: 7px;

                content: "";
                position: absolute;
                top: calc(50% - #{$borderWidth} / 2);
                right: 0;
                pointer-events: none;
                width: 0;
                height: 0;
                opacity: 0.3;
                margin-left: 10px;
                border: $borderWidth solid transparent;
                border-top-color: $color-black;
            }
        }

        &--current {
            @extend .uk-button-purple;

            box-shadow: none;
            pointer-events: none;
        }

        &--disabled {
            opacity: 0.5;
            pointer-events: none;
        }

        &--prev,
        &--next {
            @extend .uk-button-nav, .uk-button-border-dashed, .uk-button-border-dark-purple;

            padding: 0;

            & #{$block}__link {
                padding-top: 0;
                color: inherit;
            }

            & #{$block}__link:hover,
            & #{$block}__link:active {
                background-color: transparent;
            }

            & #{$block}__link:focus {
                background-color: rgba(#e9e9e9, 0.3);
            }
        }

        &--current,
        &--disabled,
        &--prev,
        &--next {
            box-shadow: none;

            @media (hover: hover) {
                &:hover,
                &:focus {
                    box-shadow: none;
                }
            }
        }

        &--next {
            @extend .uk-button-nav-next;
        }

        &--prev {
            @extend .uk-button-nav-prev;
        }

        svg {
            stroke: currentColor;
            height: 14px;

            @include vw-xsmall-down {
                height: 30px;
                width: 10px;
            }
        }
    }
}
