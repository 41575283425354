*,
*::before,
*::after {
  box-sizing: border-box;
}

ul,
ol {
  padding: 0;
  list-style: none;
}

body,
h1,
h2,
h3,
h4,
p,
ul,
ol,
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
}

html {
  font-size: 100%;
  -webkit-tap-highlight-color: transparent;
  scroll-behavior: smooth;
}

body {
  min-height: 100vh;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a:not([class]) {
  text-decoration-skip-ink: auto;
}

img {
  max-width: 100%;
  display: block;
}

input,
button,
textarea,
select {
  font: inherit;
}

@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}
textarea {
  resize: vertical;
}

iframe {
  max-width: 100%;
}

fieldset {
  min-width: 0;
}

th {
  font-weight: inherit;
  text-align: inherit;
}

.show-for-sr {
  position: absolute;
  top: 0;
  visibility: hidden;
  size: 1px;
}

.no-js .show-for-js {
  display: none;
}

.js .hide-for-js {
  display: none;
}

.responsiveEmbed {
  position: relative;
  padding: 0 0 56.25%;
}
.responsiveEmbed iframe {
  position: absolute;
  top: 0;
  left: 0;
  size: 100% !important;
}

.sf-dump samp {
  white-space: pre;
}

/* ========================================================================
   Component: Base
 ========================================================================== */
/*
 * 1. Set `font-size` to support `rem` units
 *    Not using `font` property because a leading hyphen (e.g. -apple-system) causes the font to break in IE11 and Edge
 * 2. Prevent adjustments of font size after orientation changes in iOS.
 * 3. Style
 */
html {
  /* 1 */
  font-family: "Geometria", sans-serif;
  font-size: 16px;
  font-weight: normal;
  line-height: 1.5;
  /* 2 */
  -webkit-text-size-adjust: 100%;
  /* 3 */
  background: white;
  color: #231f20;
}

/*
 * Remove the margin in all browsers.
 */
body {
  margin: 0;
}

/* Links
 ========================================================================== */
/*
 * Remove the outline on focused links when they are also active or hovered
 */
a:active,
a:hover {
  outline: none;
}

/*
 * Style
 */
a,
.uk-link {
  color: #1e87f0;
  text-decoration: none;
  cursor: pointer;
  transition: color 0.25s ease-in-out;
}

a:hover,
.uk-link:hover,
.uk-link-toggle:hover .uk-link,
.uk-link-toggle:focus .uk-link {
  color: #0f6ecd;
  text-decoration: none;
}

/* Text-level semantics
 ========================================================================== */
/*
 * 1. Add the correct text decoration in Edge.
 * 2. The shorthand declaration `underline dotted` is not supported in Safari.
 */
abbr[title] {
  /* 1 */
  text-decoration: underline dotted;
  /* 2 */
  -webkit-text-decoration-style: dotted;
}

/*
 * Add the correct font weight in Chrome, Edge, and Safari.
 */
b,
strong {
  font-weight: bolder;
}

/*
 * 1. Consolas has a better baseline in running text compared to `Courier`
 * 2. Correct the odd `em` font sizing in all browsers.
 * 3. Style
 */
:not(pre) > code,
:not(pre) > kbd,
:not(pre) > samp {
  /* 1 */
  font-family: Consolas, monaco, monospace;
  /* 2 */
  font-size: 0.875rem;
  /* 3 */
  color: #f0506e;
  white-space: nowrap;
}

/*
 * Emphasize
 */
em {
  color: inherit;
}

/*
 * Insert
 */
ins {
  background: #ffd;
  color: #666;
  text-decoration: none;
}

/*
 * Mark
 */
mark {
  background: #ffd;
  color: #666;
}

/*
 * Quote
 */
q {
  font-style: italic;
}

/*
 * Add the correct font size in all browsers.
 */
small {
  font-size: 80%;
}

/*
 * Prevents `sub` and `sup` affecting `line-height` in all browsers.
 */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

/* Embedded content
 ========================================================================== */
/*
 * Remove the gap between embedded content and the bottom of their containers.
 */
audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

/*
 * 1. Add responsiveness.
 * 2. Auto-scale the height. Only needed if `height` attribute is present.
 * 3. Corrects responsive `max-width` behavior if padding and border are used.
 * 4. Exclude SVGs for IE11 because they don't preserve their aspect ratio.
 */
canvas,
img,
video {
  /* 1 */
  max-width: 100%;
  /* 2 */
  height: auto;
  /* 3 */
  box-sizing: border-box;
}

/* 4 */
@supports (display: block) {
  svg {
    max-width: 100%;
    height: auto;
    box-sizing: border-box;
  }
}
/*
 * Hide the overflow in IE.
 */
svg:not(:root) {
  overflow: hidden;
}

/*
 * 1. Fix lazy loading images if parent element is set to `display: inline` and has `overflow: hidden`.
 * 2. Hide `alt` text for lazy loading images.
 * Note: Selector for background while loading img[data-src*='.jpg'][src*='data:image'] { background: grey; }
 */
img:not([src]) {
  /* 1 */
  min-width: 1px;
  /* 2 */
  visibility: hidden;
}

/*
 * Iframe
 * Remove border in all browsers
 */
iframe {
  border: 0;
}

/* Block elements
 ========================================================================== */
/*
 * Margins
 */
p,
ul,
ol,
dl,
pre,
address,
fieldset,
figure {
  margin: 0 0 0 0;
}

/* Add margin if adjacent element */
* + p,
* + ul,
* + ol,
* + dl,
* + pre,
* + address,
* + fieldset,
* + figure {
  margin-top: 0;
}

/* Headings
 ========================================================================== */
h1, .uk-h1,
h2, .uk-h2,
h3, .uk-h3,
h4, .uk-h4,
h5, .uk-h5,
h6, .uk-h6,
.uk-heading-small,
.uk-heading-medium,
.uk-heading-large,
.uk-heading-xlarge,
.uk-heading-2xlarge {
  margin: 0 0 0 0;
  font-family: "Geometria", sans-serif;
  font-weight: normal;
  color: #231f20;
  text-transform: none;
}

/* Add margin if adjacent element */
* + h1, * + .uk-h1,
* + h2, * + .uk-h2,
* + h3, * + .uk-h3,
* + h4, * + .uk-h4,
* + h5, * + .uk-h5,
* + h6, * + .uk-h6,
* + .uk-heading-small,
* + .uk-heading-medium,
* + .uk-heading-large,
* + .uk-heading-xlarge,
* + .uk-heading-2xlarge {
  margin-top: 40px;
}

/*
 * Sizes
 */
h1, .uk-h1 {
  font-size: 2.23125rem;
  line-height: 1.2;
}

h2, .uk-h2 {
  font-size: 1.7rem;
  line-height: 1.3;
}

h3, .uk-h3 {
  font-size: 1.5rem;
  line-height: 1.4;
}

h4, .uk-h4 {
  font-size: 1.25rem;
  line-height: 1.4;
}

h5, .uk-h5 {
  font-size: 16px;
  line-height: 1.4;
}

h6, .uk-h6 {
  font-size: 0.875rem;
  line-height: 1.4;
}

/* Tablet landscape and bigger */
@media (min-width: 900px) {
  h1, .uk-h1 {
    font-size: 2.625rem;
  }

  h2, .uk-h2 {
    font-size: 2rem;
  }
}
/* Lists
 ========================================================================== */
ul,
ol {
  padding-left: 0;
}

/*
 * Reset margin for nested lists
 */
ul > li > ul,
ul > li > ol,
ol > li > ol,
ol > li > ul {
  margin: 0;
}

/* Description lists
 ========================================================================== */
dt {
  font-weight: bold;
}

dd {
  margin-left: 0;
}

/* Horizontal rules
 ========================================================================== */
/*
 * 1. Show the overflow in Chrome, Edge and IE.
 * 2. Add the correct text-align in Edge and IE.
 * 3. Style
 */
hr, .uk-hr {
  /* 1 */
  overflow: visible;
  /* 2 */
  text-align: inherit;
  /* 3 */
  margin: 0 0 20px 0;
  border: 0;
  border-top: 1px solid #e5e5e5;
}

/* Add margin if adjacent element */
* + hr,
* + .uk-hr {
  margin-top: 20px;
}

/* Address
 ========================================================================== */
address {
  font-style: normal;
}

/* Blockquotes
 ========================================================================== */
blockquote {
  margin: 0 0 20px 0;
  font-size: 1.25rem;
  line-height: 1.5;
  font-style: italic;
}

/* Add margin if adjacent element */
* + blockquote {
  margin-top: 20px;
}

/*
 * Content
 */
blockquote p:last-of-type {
  margin-bottom: 0;
}

blockquote footer {
  margin-top: 10px;
  font-size: 0.875rem;
  line-height: 1.5;
}

/* Preformatted text
 ========================================================================== */
/*
 * 1. Contain overflow in all browsers.
 */
pre {
  font: 0.875rem / 1.5 Consolas, monaco, monospace;
  color: #666;
  -moz-tab-size: 4;
  tab-size: 4;
  /* 1 */
  overflow: auto;
}

pre code {
  font-family: Consolas, monaco, monospace;
}

/* Selection pseudo-element
 ========================================================================== */
::selection {
  background: #39f;
  color: #fff;
  text-shadow: none;
}

/* HTML5 elements
 ========================================================================== */
/*
 * 1. Add the correct display in Edge, IE 10+, and Firefox.
 * 2. Add the correct display in IE.
 */
details,
main {
  /* 2 */
  display: block;
}

/*
 * Add the correct display in all browsers.
 */
summary {
  display: list-item;
}

/*
 * Add the correct display in IE.
 */
template {
  display: none;
}

/* Pass media breakpoints to JS
 ========================================================================== */
/*
 * Breakpoints
 */
.uk-breakpoint-s::before {
  content: "640px";
}

.uk-breakpoint-m::before {
  content: "900px";
}

.uk-breakpoint-l::before {
  content: "1000px";
}

.uk-breakpoint-xl::before {
  content: "1200px";
}

:root {
  --uk-breakpoint-s: 640px;
  --uk-breakpoint-m: 900px;
  --uk-breakpoint-l: 1000px;
  --uk-breakpoint-xl: 1200px;
}

/* ========================================================================
   Component: Icon
 ========================================================================== */
/*
 * Note: 1. - 7. is required for `button` elements. Needed for Close and Form Icon component.
 * 1. Remove margins in Chrome, Safari and Opera.
 * 2. Remove borders for `button`.
 * 3. Remove border-radius in Chrome.
 * 4. Address `overflow` set to `hidden` in IE.
 * 5. Correct `font` properties and `color` not being inherited for `button`.
 * 6. Remove the inheritance of text transform in Edge, Firefox, and IE.
 * 7. Remove default `button` padding and background color
 * 8. Style
 * 9. Fill all SVG elements with the current text color if no `fill` attribute is set
 * 10. Let the container fit the height of the icon
 */
.uk-icon {
  /* 1 */
  margin: 0;
  /* 2 */
  border: none;
  /* 3 */
  border-radius: 0;
  /* 4 */
  overflow: visible;
  /* 5 */
  font: inherit;
  color: inherit;
  /* 6 */
  text-transform: none;
  /* 7. */
  padding: 0;
  background-color: transparent;
  /* 8 */
  display: inline-block;
  /* 9 */
  fill: currentcolor;
  /* 10 */
  line-height: 0;
}

/* Required for `button`. */
button.uk-icon:not(:disabled) {
  cursor: pointer;
}

/*
 * Remove the inner border and padding in Firefox.
 */
.uk-icon::-moz-focus-inner {
  border: 0;
  padding: 0;
}

/*
 * Set the fill and stroke color of all SVG elements to the current text color
 */
.uk-icon:not(.uk-preserve) [fill*="#"]:not(.uk-preserve) {
  fill: currentcolor;
}

.uk-icon:not(.uk-preserve) [stroke*="#"]:not(.uk-preserve) {
  stroke: currentcolor;
}

/*
 * Fix Firefox blurry SVG rendering: https://bugzilla.mozilla.org/show_bug.cgi?id=1046835
 */
.uk-icon > * {
  transform: translate(0, 0);
}

/* Image modifier
 ========================================================================== */
/*
 * Display images in icon dimensions
 */
.uk-icon-image {
  width: 20px;
  height: 20px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: contain;
  vertical-align: middle;
}

/* Style modifiers
 ========================================================================== */
/*
 * Link
 */
.uk-icon-link {
  color: #999;
}

.uk-icon-link:hover,
.uk-icon-link:focus {
  color: #666;
  outline: none;
}

/* OnClick + Active */
.uk-icon-link:active,
.uk-active > .uk-icon-link {
  color: #595959;
}

/*
 * Button
 * 1. Center icon vertically and horizontally
 */
.uk-icon-button {
  box-sizing: border-box;
  width: 36px;
  height: 36px;
  border-radius: 500px;
  background: #f8f8f8;
  color: #999;
  vertical-align: middle;
  /* 1 */
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

/* Hover + Focus */
.uk-icon-button:hover,
.uk-icon-button:focus {
  background-color: #ebebeb;
  color: #666;
  outline: none;
}

/* OnClick + Active */
.uk-icon-button:active,
.uk-active > .uk-icon-button {
  background-color: #dfdfdf;
  color: #666;
}

/* ========================================================================
   Component: Form Range
 ========================================================================== */
/*
 * 1. Normalize and defaults
 * 2. Prevent content overflow if a fixed width is used
 * 3. Take the full width
 * 4. Remove default style
 * 5. Remove white background in Chrome
 * 6. Remove padding in IE11
 */
.uk-range {
  /* 1 */
  box-sizing: border-box;
  margin: 0;
  vertical-align: middle;
  /* 2 */
  max-width: 100%;
  /* 3 */
  width: 100%;
  /* 4 */
  -webkit-appearance: none;
  /* 5 */
  background: transparent;
  /* 6 */
  padding: 0;
}

/* Focus */
.uk-range:focus {
  outline: none;
}

.uk-range::-moz-focus-outer {
  border: none;
}

/* IE11 Reset */
.uk-range::-ms-track {
  height: 15px;
  background: transparent;
  border-color: transparent;
  color: transparent;
}

/*
 * Improves consistency of cursor style for clickable elements
 */
.uk-range:not(:disabled)::-webkit-slider-thumb {
  cursor: pointer;
}

.uk-range:not(:disabled)::-moz-range-thumb {
  cursor: pointer;
}

.uk-range:not(:disabled)::-ms-thumb {
  cursor: pointer;
}

/* Thumb
 ========================================================================== */
/*
 * 1. Reset
 * 2. Style
 */
/* Webkit */
.uk-range::-webkit-slider-thumb {
  /* 1 */
  -webkit-appearance: none;
  margin-top: -7px;
  /* 2 */
  height: 15px;
  width: 15px;
  border-radius: 500px;
  background: #666;
}

/* Firefox */
.uk-range::-moz-range-thumb {
  /* 1 */
  border: none;
  /* 2 */
  height: 15px;
  width: 15px;
  border-radius: 500px;
  background: #666;
}

/* Edge */
.uk-range::-ms-thumb {
  /* 1 */
  margin-top: 0;
}

/* IE11 */
.uk-range::-ms-thumb {
  /* 1 */
  border: none;
  /* 2 */
  height: 15px;
  width: 15px;
  border-radius: 500px;
  background: #666;
}

/* Edge + IE11 */
.uk-range::-ms-tooltip {
  display: none;
}

/* Track
 ========================================================================== */
/*
 * 1. Safari doesn't have a focus state. Using active instead.
 */
/* Webkit */
.uk-range::-webkit-slider-runnable-track {
  height: 3px;
  background: #ebebeb;
}

.uk-range:focus::-webkit-slider-runnable-track,
.uk-range:active::-webkit-slider-runnable-track {
  background: #d2d2d2;
}

/* Firefox */
.uk-range::-moz-range-track {
  height: 3px;
  background: #ebebeb;
}

.uk-range:focus::-moz-range-track {
  background: #d2d2d2;
}

/* Edge */
.uk-range::-ms-fill-lower,
.uk-range::-ms-fill-upper {
  height: 3px;
  background: #ebebeb;
}

.uk-range:focus::-ms-fill-lower,
.uk-range:focus::-ms-fill-upper {
  background: #d2d2d2;
}

/* ========================================================================
   Component: Form
 ========================================================================== */
/*
 * 1. Define consistent box sizing.
 *    Default is `content-box` with following exceptions set to `border-box`
 *    `select`, `input[type="checkbox"]` and `input[type="radio"]`
 *    `input[type="search"]` in Chrome, Safari and Opera
 *    `input[type="color"]` in Firefox
 * 2. Address margins set differently in Firefox/IE and Chrome/Safari/Opera.
 * 3. Remove `border-radius` in iOS.
 * 4. Change font properties to `inherit` in all browsers.
 */
.uk-input, .SiteForm__input,
.uk-select,
.uk-textarea,
.SiteForm__textarea,
.uk-radio,
.uk-checkbox {
  /* 1 */
  box-sizing: border-box;
  /* 2 */
  margin: 0;
  /* 3 */
  border-radius: 0;
  /* 4 */
  font: inherit;
}

/*
 * Show the overflow in Edge.
 */
.uk-input, .SiteForm__input {
  overflow: visible;
}

/*
 * Remove the inheritance of text transform in Firefox.
 */
.uk-select {
  text-transform: none;
}

/*
 * 1. Change font properties to `inherit` in all browsers
 * 2. Don't inherit the `font-weight` and use `bold` instead.
 * NOTE: Both declarations don't work in Chrome, Safari and Opera.
 */
.uk-select optgroup {
  /* 1 */
  font: inherit;
  /* 2 */
  font-weight: bold;
}

/*
 * Remove the default vertical scrollbar in IE 10+.
 */
.uk-textarea, .SiteForm__textarea {
  overflow: auto;
}

/*
 * Remove the inner padding and cancel buttons in Chrome on OS X and Safari on OS X.
 */
.uk-input[type=search]::-webkit-search-cancel-button, [type=search].SiteForm__input::-webkit-search-cancel-button,
.uk-input[type=search]::-webkit-search-decoration,
[type=search].SiteForm__input::-webkit-search-decoration {
  -webkit-appearance: none;
}

/*
 * Correct the cursor style of increment and decrement buttons in Chrome.
 */
.uk-input[type=number]::-webkit-inner-spin-button, [type=number].SiteForm__input::-webkit-inner-spin-button,
.uk-input[type=number]::-webkit-outer-spin-button,
[type=number].SiteForm__input::-webkit-outer-spin-button {
  height: auto;
}

/*
 * Removes placeholder transparency in Firefox.
 */
.uk-input::-moz-placeholder, .SiteForm__input::-moz-placeholder,
.uk-textarea::-moz-placeholder,
.SiteForm__textarea::-moz-placeholder {
  opacity: 1;
}

/*
 * Improves consistency of cursor style for clickable elements
 */
.uk-radio:not(:disabled),
.uk-checkbox:not(:disabled) {
  cursor: pointer;
}

/*
 * Define consistent border, margin, and padding.
 */
.uk-fieldset {
  border: none;
  margin: 0;
  padding: 0;
}

/* Input, select and textarea
 * Allowed: `text`, `password`, `datetime`, `datetime-local`, `date`,  `month`,
            `time`, `week`, `number`, `email`, `url`, `search`, `tel`, `color`
 * Disallowed: `range`, `radio`, `checkbox`, `file`, `submit`, `reset` and `image`
 ========================================================================== */
/*
 * Remove default style in iOS.
 */
.uk-input, .SiteForm__input,
.uk-textarea,
.SiteForm__textarea {
  -webkit-appearance: none;
}

/*
 * 1. Prevent content overflow if a fixed width is used
 * 2. Take the full width
 * 3. Reset default
 * 4. Style
 */
.uk-input, .SiteForm__input,
.uk-select,
.uk-textarea,
.SiteForm__textarea {
  /* 1 */
  max-width: 100%;
  /* 2 */
  width: 100%;
  /* 3 */
  border: 0 none;
  /* 4 */
  padding: 0 10px;
  background: transparent;
  color: #471045;
  border: 1px solid rgba(71, 16, 69, 0.2);
  border-radius: 5px;
}

/*
 * Single-line
 * 1. Allow any element to look like an `input` or `select` element
 * 2. Make sure line-height is not larger than height
 *    Also needed to center the text vertically
 */
.uk-input, .SiteForm__input,
.uk-select:not([multiple]):not([size]) {
  height: 48px;
  vertical-align: middle;
  /* 1 */
  display: inline-block;
}

/* 2 */
.uk-input:not(input), .SiteForm__input:not(input),
.uk-select:not(select) {
  line-height: 48px;
}

/*
 * Multi-line
 */
.uk-select[multiple],
.uk-select[size],
.uk-textarea,
.SiteForm__textarea {
  padding-top: 4px;
  padding-bottom: 4px;
  vertical-align: top;
}

/* Focus */
.uk-input:focus, .SiteForm__input:focus,
.uk-select:focus,
.uk-textarea:focus,
.SiteForm__textarea:focus {
  outline: none;
  background-color: transparent;
  color: inherit;
}

/* Disabled */
.uk-input:disabled, .SiteForm__input:disabled,
.uk-select:disabled,
.uk-textarea:disabled,
.SiteForm__textarea:disabled {
  background-color: #f8f8f8;
  color: #999;
}

/*
 * Placeholder
 */
.uk-input::-ms-input-placeholder, .SiteForm__input::-ms-input-placeholder {
  color: #999 !important;
}

.uk-input::placeholder, .SiteForm__input::placeholder {
  color: #999;
}

.uk-textarea::-ms-input-placeholder, .SiteForm__textarea::-ms-input-placeholder {
  color: #999 !important;
}

.uk-textarea::placeholder, .SiteForm__textarea::placeholder {
  color: #999;
}

/* Style modifier (`uk-input`, `uk-select` and `uk-textarea`)
 ========================================================================== */
/*
 * Small
 */
.uk-form-small {
  font-size: 0.875rem;
}

.uk-form-small:not(textarea):not([multiple]):not([size]) {
  height: 30px;
  padding-left: 8px;
  padding-right: 8px;
}

.uk-form-small:not(select):not(input):not(textarea) {
  line-height: 30px;
}

/*
 * Large
 */
.uk-form-large {
  font-size: 1.25rem;
}

.uk-form-large:not(textarea):not([multiple]):not([size]) {
  height: 55px;
  padding-left: 12px;
  padding-right: 12px;
}

.uk-form-large:not(select):not(input):not(textarea) {
  line-height: 55px;
}

/* Style modifier (`uk-input`, `uk-select` and `uk-textarea`)
 ========================================================================== */
/*
 * Error
 */
.uk-form-danger,
.uk-form-danger:focus {
  color: #f0506e;
}

/*
 * Success
 */
.uk-form-success,
.uk-form-success:focus {
  color: transparent;
}

/*
 * Blank
 */
.uk-form-blank {
  background: none;
}

/* Width modifiers (`uk-input`, `uk-select` and `uk-textarea`)
 ========================================================================== */
/*
 * Fixed widths
 * Different widths for mini sized `input` and `select` elements
 */
input.uk-form-width-xsmall {
  width: 50px;
}

select.uk-form-width-xsmall {
  width: 75px;
}

.uk-form-width-small {
  width: 130px;
}

.uk-form-width-medium {
  width: 200px;
}

.uk-form-width-large {
  width: 500px;
}

/* Select
 ========================================================================== */
/*
 * 1. Remove default style. Also works in Firefox
 * 2. Style
 * 3. Remove default style in IE 10/11
 * 4. Set `color` for options in the select dropdown, because the inherited `color` might be too light.
 */
.uk-select:not([multiple]):not([size]) {
  /* 1 */
  -webkit-appearance: none;
  -moz-appearance: none;
  /* 2 */
  padding-right: 20px;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2224%22%20height%3D%2216%22%20viewBox%3D%220%200%2024%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22%23666%22%20points%3D%2212%201%209%206%2015%206%22%20%2F%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22%23666%22%20points%3D%2212%2013%209%208%2015%208%22%20%2F%3E%0A%3C%2Fsvg%3E%0A");
  background-repeat: no-repeat;
  background-position: 100% 50%;
}

/* 3 */
.uk-select:not([multiple]):not([size])::-ms-expand {
  display: none;
}

/* 4 */
.uk-select:not([multiple]):not([size]) option {
  color: #444;
}

/*
 * Disabled
 */
.uk-select:not([multiple]):not([size]):disabled {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2224%22%20height%3D%2216%22%20viewBox%3D%220%200%2024%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22%23999%22%20points%3D%2212%201%209%206%2015%206%22%20%2F%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22%23999%22%20points%3D%2212%2013%209%208%2015%208%22%20%2F%3E%0A%3C%2Fsvg%3E%0A");
}

/* Datalist
 ========================================================================== */
/*
 * 1. Remove default style in Chrome
 */
.uk-input[list], [list].SiteForm__input {
  padding-right: 20px;
  background-repeat: no-repeat;
  background-position: 100% 50%;
}

.uk-input[list]:hover, [list].SiteForm__input:hover,
.uk-input[list]:focus,
[list].SiteForm__input:focus {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2224%22%20height%3D%2216%22%20viewBox%3D%220%200%2024%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22%23666%22%20points%3D%2212%2012%208%206%2016%206%22%20%2F%3E%0A%3C%2Fsvg%3E%0A");
}

/* 1 */
.uk-input[list]::-webkit-calendar-picker-indicator, [list].SiteForm__input::-webkit-calendar-picker-indicator {
  display: none;
}

/* Radio and checkbox
 * Note: Does not work in IE11
 ========================================================================== */
/*
 * 1. Style
 * 2. Make box more robust so it clips the child element
 * 3. Vertical alignment
 * 4. Remove default style
 * 5. Fix black background on iOS
 * 6. Center icons
 */
.uk-radio,
.uk-checkbox {
  /* 1 */
  display: inline-block;
  height: 16px;
  width: 16px;
  /* 2 */
  overflow: hidden;
  /* 3 */
  margin-top: -4px;
  vertical-align: middle;
  /* 4 */
  -webkit-appearance: none;
  -moz-appearance: none;
  /* 5 */
  background-color: #ebebeb;
  /* 6 */
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

.uk-radio {
  border-radius: 50%;
}

/* Focus */
.uk-radio:focus,
.uk-checkbox:focus {
  outline: none;
}

/*
 * Checked
 */
.uk-radio:checked,
.uk-checkbox:checked,
.uk-checkbox:indeterminate {
  background-color: #1e87f0;
}

/* Focus */
.uk-radio:checked:focus,
.uk-checkbox:checked:focus,
.uk-checkbox:indeterminate:focus {
  background-color: #0e6ecd;
}

/*
 * Icons
 */
.uk-radio:checked {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Ccircle%20fill%3D%22%23fff%22%20cx%3D%228%22%20cy%3D%228%22%20r%3D%222%22%20%2F%3E%0A%3C%2Fsvg%3E");
}

.uk-checkbox:checked {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2214%22%20height%3D%2211%22%20viewBox%3D%220%200%2014%2011%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22%23fff%22%20points%3D%2212%201%205%207.5%202%205%201%205.5%205%2010%2013%201.5%22%20%2F%3E%0A%3C%2Fsvg%3E%0A");
}

.uk-checkbox:indeterminate {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Crect%20fill%3D%22%23fff%22%20x%3D%223%22%20y%3D%228%22%20width%3D%2210%22%20height%3D%221%22%20%2F%3E%0A%3C%2Fsvg%3E");
}

/*
 * Disabled
 */
.uk-radio:disabled,
.uk-checkbox:disabled {
  background-color: #f8f8f8;
}

.uk-radio:disabled:checked {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Ccircle%20fill%3D%22%23999%22%20cx%3D%228%22%20cy%3D%228%22%20r%3D%222%22%20%2F%3E%0A%3C%2Fsvg%3E");
}

.uk-checkbox:disabled:checked {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2214%22%20height%3D%2211%22%20viewBox%3D%220%200%2014%2011%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22%23999%22%20points%3D%2212%201%205%207.5%202%205%201%205.5%205%2010%2013%201.5%22%20%2F%3E%0A%3C%2Fsvg%3E%0A");
}

.uk-checkbox:disabled:indeterminate {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Crect%20fill%3D%22%23999%22%20x%3D%223%22%20y%3D%228%22%20width%3D%2210%22%20height%3D%221%22%20%2F%3E%0A%3C%2Fsvg%3E");
}

/* Legend
 ========================================================================== */
/*
 * Legend
 * 1. Behave like block element
 * 2. Correct the color inheritance from `fieldset` elements in IE.
 * 3. Remove padding so people aren't caught out if they zero out fieldsets.
 * 4. Style
 */
.uk-legend {
  /* 1 */
  width: 100%;
  /* 2 */
  color: inherit;
  /* 3 */
  padding: 0;
  /* 4 */
  font-size: 1.5rem;
  line-height: 1.4;
}

/* Custom controls
 ========================================================================== */
/*
 * 1. Container fits its content
 * 2. Create position context
 * 3. Prevent content overflow
 * 4. Behave like most inline-block elements
 */
.uk-form-custom {
  /* 1 */
  display: inline-block;
  /* 2 */
  position: relative;
  /* 3 */
  max-width: 100%;
  /* 4 */
  vertical-align: middle;
}

/*
 * 1. Position and resize the form control to always cover its container
 * 2. Required for Firefox for positioning to the left
 * 3. Required for Webkit to make `height` work
 * 4. Hide controle and show cursor
 * 5. Needed for the cursor
 * 6. Clip height caused by 5. Needed for Webkit only
 */
.uk-form-custom select,
.uk-form-custom input[type=file] {
  /* 1 */
  position: absolute;
  top: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  /* 2 */
  left: 0;
  /* 3 */
  -webkit-appearance: none;
  /* 4 */
  opacity: 0;
  cursor: pointer;
}

.uk-form-custom input[type=file] {
  /* 5 */
  font-size: 500px;
  /* 6 */
  overflow: hidden;
}

/* Label
 ========================================================================== */
/* Layout
 ========================================================================== */
/*
 * Stacked
 */
.uk-form-stacked .uk-form-label {
  display: block;
  margin-bottom: 10px;
}

/*
 * Horizontal
 */
/* Tablet portrait and smaller */
@media (max-width: 899px) {
  /* Behave like `uk-form-stacked` */
  .uk-form-horizontal .uk-form-label {
    display: block;
    margin-bottom: 10px;
  }
}
/* Tablet landscape and bigger */
@media (min-width: 900px) {
  .uk-form-horizontal .uk-form-label {
    width: 200px;
    margin-top: 7px;
    float: left;
  }

  .uk-form-horizontal .uk-form-controls {
    margin-left: 215px;
  }

  /* Better vertical alignment if controls are checkboxes and radio buttons with text */
  .uk-form-horizontal .uk-form-controls-text {
    padding-top: 7px;
  }
}
/* Icons
 ========================================================================== */
/*
 * 1. Set position
 * 2. Set width
 * 3. Center icon vertically and horizontally
 * 4. Style
 */
.uk-form-icon {
  /* 1 */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  /* 2 */
  width: 48px;
  /* 3 */
  display: inline-flex;
  justify-content: center;
  align-items: center;
  /* 4 */
  color: #999;
}

/*
 * Required for `a`.
 */
.uk-form-icon:hover {
  color: #666;
}

/*
 * Make `input` element clickable through icon, e.g. if it's a `span`
 */
.uk-form-icon:not(a):not(button):not(input) {
  pointer-events: none;
}

/*
 * Input padding
 */
.uk-form-icon:not(.uk-form-icon-flip) ~ .uk-input, .uk-form-icon:not(.uk-form-icon-flip) ~ .SiteForm__input {
  padding-left: 48px !important;
}

/*
 * Position modifier
 */
.uk-form-icon-flip {
  right: 0;
  left: auto;
}

.uk-form-icon-flip ~ .uk-input, .uk-form-icon-flip ~ .SiteForm__input {
  padding-right: 48px !important;
}

/* ========================================================================
   Component: Button
 ========================================================================== */
/*
 * 1. Remove margins in Chrome, Safari and Opera.
 * 2. Remove borders for `button`.
 * 3. Address `overflow` set to `hidden` in IE.
 * 4. Correct `font` properties and `color` not being inherited for `button`.
 * 5. Remove the inheritance of text transform in Edge, Firefox, and IE.
 * 6. Remove default style for `input type="submit"`in iOS.
 * 7. Style
 * 8. `line-height` is used to create a height because it also centers the text vertically for `a` elements.
 *    Better would be to use height and flexbox to center the text vertically but flexbox doesn't work in Firefox on `button` elements.
 * 9. Align text if button has a width
 * 10. Required for `a`.
 */
.uk-button, .ErrorPage__homeButton, .ListPagination__item, .ListPagination__mobileSelect, .ProposalsList__button, .Modal__close, .SiteForm__buttonSend, .SiteForm__buttonAttach {
  /* 1 */
  margin: 0;
  /* 2 */
  border: none;
  /* 3 */
  overflow: visible;
  /* 4 */
  font: inherit;
  color: inherit;
  /* 5 */
  text-transform: none;
  /* 6 */
  -webkit-appearance: none;
  border-radius: 0;
  /* 7 */
  display: inline-block;
  box-sizing: border-box;
  padding: 0 32px;
  vertical-align: middle;
  font-size: 1rem;
  /* 8 */
  line-height: 3rem;
  /* 9 */
  text-align: center;
  /* 10 */
  text-decoration: none;
  font-weight: 700;
  transition: background-color 0.25s ease-in-out, color 0.2s ease-in-out;
}

.uk-button:not(:disabled), .ErrorPage__homeButton:not(:disabled), .ListPagination__item:not(:disabled), .ListPagination__mobileSelect:not(:disabled), .ProposalsList__button:not(:disabled), .Modal__close:not(:disabled), .SiteForm__buttonSend:not(:disabled), .SiteForm__buttonAttach:not(:disabled) {
  cursor: pointer;
}

/*
 * Remove the inner border and padding in Firefox.
 */
.uk-button::-moz-focus-inner, .ErrorPage__homeButton::-moz-focus-inner, .ListPagination__item::-moz-focus-inner, .ListPagination__mobileSelect::-moz-focus-inner, .ProposalsList__button::-moz-focus-inner, .Modal__close::-moz-focus-inner, .SiteForm__buttonSend::-moz-focus-inner, .SiteForm__buttonAttach::-moz-focus-inner {
  border: 0;
  padding: 0;
}

/* Hover */
.uk-button:hover, .ErrorPage__homeButton:hover, .ListPagination__item:hover, .ListPagination__mobileSelect:hover, .ProposalsList__button:hover, .Modal__close:hover, .SiteForm__buttonSend:hover, .SiteForm__buttonAttach:hover {
  /* 9 */
  text-decoration: none;
}

/* Focus */
.uk-button:focus, .ErrorPage__homeButton:focus, .ListPagination__item:focus, .ListPagination__mobileSelect:focus, .ProposalsList__button:focus, .Modal__close:focus, .SiteForm__buttonSend:focus, .SiteForm__buttonAttach:focus {
  outline: none;
}

/* OnClick + Active */
/* Style modifiers
 ========================================================================== */
/*
 * Default
 */
.uk-button-default {
  background-color: #f8f8f8;
  color: #333;
}

/* Hover + Focus */
.uk-button-default:hover,
.uk-button-default:focus {
  background-color: #ebebeb;
  color: #333;
}

/* OnClick + Active */
.uk-button-default:active,
.uk-button-default.uk-active {
  background-color: #dfdfdf;
  color: #333;
}

/*
 * Primary
 */
.uk-button-primary {
  background-color: #1e87f0;
  color: #fff;
}

/* Hover + Focus */
.uk-button-primary:hover,
.uk-button-primary:focus {
  background-color: #0f7ae5;
  color: #fff;
}

/* OnClick + Active */
.uk-button-primary:active,
.uk-button-primary.uk-active {
  background-color: #0e6ecd;
  color: #fff;
}

/*
 * Secondary
 */
.uk-button-secondary {
  background-color: #222;
  color: #fff;
}

/* Hover + Focus */
.uk-button-secondary:hover,
.uk-button-secondary:focus {
  background-color: #151515;
  color: #fff;
}

/* OnClick + Active */
.uk-button-secondary:active,
.uk-button-secondary.uk-active {
  background-color: #090909;
  color: #fff;
}

/*
 * Danger
 */
.uk-button-danger {
  background-color: #f0506e;
  color: #fff;
}

/* Hover + Focus */
.uk-button-danger:hover,
.uk-button-danger:focus {
  background-color: #ee395b;
  color: #fff;
}

/* OnClick + Active */
.uk-button-danger:active,
.uk-button-danger.uk-active {
  background-color: #ec2147;
  color: #fff;
}

/*
 * Disabled
 * The same for all style modifiers
 */
.uk-button-default:disabled,
.uk-button-primary:disabled,
.uk-button-secondary:disabled,
.uk-button-danger:disabled {
  background-color: #f8f8f8;
  color: #999;
}

/* Size modifiers
 ========================================================================== */
.uk-button-small {
  padding: 0 16px;
  line-height: 2rem;
  font-size: 0.875rem;
}

.uk-button-large, .ErrorPage__homeButton {
  padding: 0 40px;
  line-height: 4rem;
  font-size: 1.25rem;
}

/* Text modifiers
 ========================================================================== */
/*
 * Text
 * 1. Reset
 * 2. Style
 */
.uk-button-text {
  /* 1 */
  padding: 0;
  line-height: 1.5;
  background: none;
  /* 2 */
  color: #999;
}

/* Hover + Focus */
.uk-button-text:hover,
.uk-button-text:focus {
  color: #666;
}

/* Disabled */
.uk-button-text:disabled {
  color: #999;
}

/*
 * Link
 * 1. Reset
 * 2. Style
 */
.uk-button-link {
  /* 1 */
  padding: 0;
  line-height: 1.5;
  background: none;
  /* 2 */
  color: #1e87f0;
}

/* Hover + Focus */
.uk-button-link:hover,
.uk-button-link:focus {
  color: #0f6ecd;
  text-decoration: underline;
}

/* Disabled */
.uk-button-link:disabled {
  color: #999;
  text-decoration: none;
}

/* Group
 ========================================================================== */
/*
 * 1. Using `flex` instead of `inline-block` to prevent whitespace betweent child elements
 * 2. Behave like button
 * 3. Create position context
 */
.uk-button-group {
  /* 1 */
  display: inline-flex;
  /* 2 */
  vertical-align: middle;
  /* 3 */
  position: relative;
}

.uk-button-round, .ListPagination__item, .Modal__close {
  width: 3rem;
  height: 3rem;
  line-height: 3.125rem;
  border-radius: 50%;
  padding: 0;
}
.uk-button-rounded, .ErrorPage__homeButton, .ProposalsList__button, .SiteForm__buttonSend {
  border-radius: 500px;
}
.uk-button-transparent, .ProposalsList__button, .Modal__close {
  background-color: transparent;
}
.uk-button-transparent:hover, .ProposalsList__button:hover, .Modal__close:hover {
  background-color: rgba(255, 255, 255, 0.2);
}
.uk-button-green, .ProposalsList--contacts .ProposalsList__button {
  color: #028c84;
}
.uk-button-light-pink {
  background-color: #ff7cb5;
  color: #ffffff;
}
.uk-button-light-pink:hover {
  color: #ffffff;
}
.uk-button-greenish {
  color: #471045;
  background-color: rgba(2, 140, 132, 0.05);
}
.uk-button-greenish:hover {
  background-color: rgba(2, 140, 132, 0.15);
  color: rgba(71, 16, 69, 0.6);
}
.uk-button-greenish:focus {
  background-color: #f6f6f6;
}
.uk-button-greenish:active {
  background-color: rgba(2, 140, 132, 0.2);
  color: rgba(71, 16, 69, 0.4);
}
.uk-button-grayish {
  background-color: rgba(35, 31, 32, 0.07);
}
.uk-button-white, .ListPagination__item--desktop {
  background-color: #ffffff;
  color: #471045;
}
.uk-button-white:hover, .ListPagination__item--desktop:hover {
  color: #471045;
  background-color: #d5cad5;
}
.uk-button-white:active, .ListPagination__item--desktop:active {
  background-color: #9f859e;
}
.uk-button-rimmed, .ErrorPage__homeButton {
  position: relative;
}
.uk-button-rimmed::after, .ErrorPage__homeButton::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  size: calc(100% + 16px);
  border: 1px dashed #ff7cb5;
  border-radius: 500px;
}
.uk-button-font-purple, .ListPagination__item {
  color: #471045;
}
.uk-button-dark-pink, .ErrorPage__homeButton, .SiteForm__buttonSend {
  background-color: #bd2056;
  color: #ffffff;
  transition: box-shadow 0.2s ease-in-out, background-color 0.2s ease-in-out;
}
.uk-button-dark-pink:hover, .ErrorPage__homeButton:hover, .SiteForm__buttonSend:hover {
  background-color: #981240;
}
.uk-button-dark-pink:focus, .ErrorPage__homeButton:focus, .SiteForm__buttonSend:focus {
  background-color: #bd2056;
  box-shadow: inset 0 0 10px #761536;
}
.uk-button-dark-pink:active, .ErrorPage__homeButton:active, .SiteForm__buttonSend:active {
  background-color: #761536;
}
.uk-button-dark-pink:hover, .ErrorPage__homeButton:hover, .SiteForm__buttonSend:hover, .uk-button-dark-pink:focus, .ErrorPage__homeButton:focus, .SiteForm__buttonSend:focus, .uk-button-dark-pink:active, .ErrorPage__homeButton:active, .SiteForm__buttonSend:active {
  color: #ffffff;
}
.uk-button-purple, .ListPagination__item--current {
  background-color: #471045;
  color: #ffffff;
}
.uk-button-purple:hover, .ListPagination__item--current:hover {
  color: #ffffff;
}
.uk-button-shadow, .ListPagination__item--desktop {
  box-shadow: 0 4px 20px rgba(71, 16, 69, 0.05);
  transition: box-shadow 0.25s ease-in-out;
}
@media (hover: hover) {
  .uk-button-shadow:hover, .ListPagination__item--desktop:hover, .uk-button-shadow:focus, .ListPagination__item--desktop:focus {
    box-shadow: -1px 2px 10px rgba(0, 0, 0, 0.1);
  }
}
.uk-button-nav:focus, .ListPagination__item--prev:focus, .ListPagination__item--next:focus {
  background-color: #e9e9e9;
}
.uk-button-nav:hover, .ListPagination__item--prev:hover, .ListPagination__item--next:hover {
  background-color: rgba(71, 16, 69, 0.06);
}
.uk-button-nav:active, .ListPagination__item--prev:active, .ListPagination__item--next:active {
  background-color: rgba(71, 16, 69, 0.15);
}
.uk-button-nav-prev svg, .ListPagination__item--prev svg {
  transform: translateX(-1px);
}
.uk-button-nav-next svg, .ListPagination__item--next svg {
  transform: translateX(1px);
}

.uk-button-border-dashed, .ListPagination__item--prev, .ListPagination__item--next, .ProposalsList__button, .Modal__close {
  border: 1px dashed #471045;
}
.uk-button-border-green {
  border-color: #028c84;
}
.uk-button-border-transparent-green, .ProposalsList--contacts .ProposalsList__button {
  border-color: rgba(2, 140, 132, 0.5);
}
.uk-button-border-white, .ProposalsList--footer .ProposalsList__button {
  border-color: #ffffff;
}
.uk-button-border-pink {
  border-color: #bd2056;
}
.uk-button-border-dark-purple, .ListPagination__item--prev, .ListPagination__item--next, .Modal__close {
  border-color: rgba(71, 16, 69, 0.3);
}

/* ========================================================================
   Component: Container
 ========================================================================== */
/*
 * 1. Box sizing has to be `content-box` so the max-width is always the same and
 *    unaffected by the padding on different breakpoints. It's important for the size modifiers.
 */
.uk-container, .SiteHeader__container, .Layout__container {
  /* 1 */
  box-sizing: content-box;
  max-width: 1136px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
}

/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-container, .SiteHeader__container, .Layout__container {
    padding-left: 30px;
    padding-right: 30px;
  }
}
/* Tablet landscape and bigger */
@media (min-width: 900px) {
  .uk-container, .SiteHeader__container, .Layout__container {
    padding-left: 40px;
    padding-right: 40px;
  }
}
/*
 * Micro clearfix
 */
.uk-container::before, .SiteHeader__container::before, .Layout__container::before,
.uk-container::after,
.SiteHeader__container::after,
.Layout__container::after {
  content: "";
  display: table;
}

.uk-container::after, .SiteHeader__container::after, .Layout__container::after {
  clear: both;
}

/*
 * Remove margin from the last-child
 */
.uk-container > :last-child, .SiteHeader__container > :last-child, .Layout__container > :last-child {
  margin-bottom: 0;
}

/*
 * Remove padding from nested containers
 */
.uk-container .uk-container, .SiteHeader__container .uk-container, .uk-container .SiteHeader__container, .SiteHeader__container .SiteHeader__container, .Layout__container .uk-container, .Layout__container .SiteHeader__container, .uk-container .Layout__container, .SiteHeader__container .Layout__container, .Layout__container .Layout__container {
  padding-left: 0;
  padding-right: 0;
}

/* Size modifier
 ========================================================================== */
.uk-container-xsmall {
  max-width: 750px;
}

.uk-container-small, .Layout__container--small {
  max-width: 900px;
}

.uk-container-large, .Layout__container--large {
  max-width: 1278px;
}

.uk-container-xlarge {
  max-width: 1600px;
}

.uk-container-expand {
  max-width: none;
}

/* Expand modifier
 ========================================================================== */
/*
 * Expand one side only
 */
.uk-container-expand-left {
  margin-left: 0;
}

.uk-container-expand-right {
  margin-right: 0;
}

/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-container-expand-left.uk-container-xsmall,
.uk-container-expand-right.uk-container-xsmall {
    max-width: calc(50% + (750px / 2) - 30px);
  }

  .uk-container-expand-left.uk-container-small, .uk-container-expand-left.Layout__container--small,
.uk-container-expand-right.uk-container-small,
.uk-container-expand-right.Layout__container--small {
    max-width: calc(50% + (900px / 2) - 30px);
  }
}
/* Tablet landscape and bigger */
@media (min-width: 900px) {
  .uk-container-expand-left,
.uk-container-expand-right {
    max-width: calc(50% + (1136px / 2) - 40px);
  }

  .uk-container-expand-left.uk-container-xsmall,
.uk-container-expand-right.uk-container-xsmall {
    max-width: calc(50% + (750px / 2) - 40px);
  }

  .uk-container-expand-left.uk-container-small, .uk-container-expand-left.Layout__container--small,
.uk-container-expand-right.uk-container-small,
.uk-container-expand-right.Layout__container--small {
    max-width: calc(50% + (900px / 2) - 40px);
  }

  .uk-container-expand-left.uk-container-large, .uk-container-expand-left.Layout__container--large,
.uk-container-expand-right.uk-container-large,
.uk-container-expand-right.Layout__container--large {
    max-width: calc(50% + (1278px / 2) - 40px);
  }

  .uk-container-expand-left.uk-container-xlarge,
.uk-container-expand-right.uk-container-xlarge {
    max-width: calc(50% + (1600px / 2) - 40px);
  }
}
/* Item
 ========================================================================== */
/*
 * Utility classes to reset container padding on the left or right side
 * Note: It has to be negative margin on the item, because it's specific to the item.
 */
.uk-container-item-padding-remove-left,
.uk-container-item-padding-remove-right {
  width: calc(100% + 15px);
}

.uk-container-item-padding-remove-left {
  margin-left: -15px;
}

.uk-container-item-padding-remove-right {
  margin-right: -15px;
}

/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-container-item-padding-remove-left,
.uk-container-item-padding-remove-right {
    width: calc(100% + 30px);
  }

  .uk-container-item-padding-remove-left {
    margin-left: -30px;
  }

  .uk-container-item-padding-remove-right {
    margin-right: -30px;
  }
}
/* Tablet landscape and bigger */
@media (min-width: 900px) {
  .uk-container-item-padding-remove-left,
.uk-container-item-padding-remove-right {
    width: calc(100% + 40px);
  }

  .uk-container-item-padding-remove-left {
    margin-left: -40px;
  }

  .uk-container-item-padding-remove-right {
    margin-right: -40px;
  }
}
/* ========================================================================
   Component: Close
 ========================================================================== */
/*
 * Adopts `uk-icon`
 */
.uk-close {
  color: #999;
}

/* Hover + Focus */
.uk-close:hover,
.uk-close:focus {
  color: #666;
  outline: none;
}

/* ========================================================================
   Component: Spinner
 ========================================================================== */
/*
 * Adopts `uk-icon`
 */
/* SVG
 ========================================================================== */
.uk-spinner > * {
  animation: uk-spinner-rotate 1.4s linear infinite;
}

@keyframes uk-spinner-rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(270deg);
  }
}
/*
 * Circle
 */
.uk-spinner > * > * {
  stroke-dasharray: 88px;
  stroke-dashoffset: 0;
  transform-origin: center;
  animation: uk-spinner-dash 1.4s ease-in-out infinite;
  stroke-width: 1;
  stroke-linecap: round;
}

@keyframes uk-spinner-dash {
  0% {
    stroke-dashoffset: 88px;
  }
  50% {
    stroke-dashoffset: 22px;
    transform: rotate(135deg);
  }
  100% {
    stroke-dashoffset: 88px;
    transform: rotate(450deg);
  }
}
/* ========================================================================
   Component: Search
 ========================================================================== */
/*
 * 1. Container fits its content
 * 2. Create position context
 * 3. Prevent content overflow
 * 4. Reset `form`
 */
.uk-search {
  /* 1 */
  display: inline-block;
  /* 2 */
  position: relative;
  /* 3 */
  max-width: 100%;
  /* 4 */
  margin: 0;
}

/* Input
 ========================================================================== */
/*
 * Remove the inner padding and cancel buttons in Chrome on OS X and Safari on OS X.
 */
.uk-search-input::-webkit-search-cancel-button,
.uk-search-input::-webkit-search-decoration {
  -webkit-appearance: none;
}

/*
 * Removes placeholder transparency in Firefox.
 */
.uk-search-input::-moz-placeholder {
  opacity: 1;
}

/*
 * 1. Define consistent box sizing.
 * 2. Address margins set differently in Firefox/IE and Chrome/Safari/Opera.
 * 3. Remove `border-radius` in iOS.
 * 4. Change font properties to `inherit` in all browsers
 * 5. Show the overflow in Edge.
 * 6. Remove default style in iOS.
 * 7. Vertical alignment
 * 8. Take the full container width
 * 9. Style
 */
.uk-search-input {
  /* 1 */
  box-sizing: border-box;
  /* 2 */
  margin: 0;
  /* 3 */
  border-radius: 0;
  /* 4 */
  font: inherit;
  /* 5 */
  overflow: visible;
  /* 6 */
  -webkit-appearance: none;
  /* 7 */
  vertical-align: middle;
  /* 8 */
  width: 100%;
  /* 9 */
  border: none;
  color: #ffffff;
  border-radius: 5px;
  font-size: 0.875rem;
}

.uk-search-input:focus {
  outline: none;
}

/* Placeholder */
.uk-search-input:-ms-input-placeholder {
  color: #999 !important;
}

.uk-search-input::placeholder {
  color: #999;
}

/* Icon (Adopts `uk-icon`)
 ========================================================================== */
/*
 * Remove default focus style
 */
.uk-search-icon:focus {
  outline: none;
}

/*
 * Position above input
 * 1. Set position
 * 2. Center icon vertically and horizontally
 * 3. Style
 */
.uk-search .uk-search-icon {
  /* 1 */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  /* 2 */
  display: inline-flex;
  justify-content: center;
  align-items: center;
  /* 3 */
  color: #999;
}

/*
 * Required for `a`.
 */
.uk-search .uk-search-icon:hover {
  color: #999;
}

/*
 * Make `input` element clickable through icon, e.g. if it's a `span`
 */
.uk-search .uk-search-icon:not(a):not(button):not(input) {
  pointer-events: none;
}

/*
 * Position modifier
 */
.uk-search .uk-search-icon-flip {
  right: 0;
  left: auto;
}

/* Default modifier
 ========================================================================== */
.uk-search-default {
  width: 180px;
}

/*
 * Input
 */
.uk-search-default .uk-search-input {
  height: 28px;
  padding-left: 6px;
  padding-right: 6px;
  background: #00766f;
  transition: background-color 0.2s ease-in-out;
}
.uk-search-default .uk-search-input::placeholder {
  transition: color 0.2s ease-in-out;
}
@media (hover: hover) and (pointer: fine) {
  .uk-search-default .uk-search-input:hover {
    background-color: #00635d;
  }
  .uk-search-default .uk-search-input:hover::placeholder {
    color: #ffffff;
  }
}

/* Focus */
.uk-search-default .uk-search-input:focus {
  background-color: #00635d;
}

/*
 * Icon
 */
.uk-search-default .uk-search-icon {
  width: 32px;
}

.uk-search-default .uk-search-icon:not(.uk-search-icon-flip) ~ .uk-search-input {
  padding-left: 32px;
}

.uk-search-default .uk-search-icon-flip ~ .uk-search-input {
  padding-right: 32px;
}

/* Navbar modifier
 ========================================================================== */
.uk-search-navbar {
  width: 400px;
}

/*
 * Input
 */
.uk-search-navbar .uk-search-input {
  height: 40px;
  background: transparent;
  font-size: 1.5rem;
}

/*
 * Icon
 */
.uk-search-navbar .uk-search-icon {
  width: 40px;
}

.uk-search-navbar .uk-search-icon:not(.uk-search-icon-flip) ~ .uk-search-input {
  padding-left: 40px;
}

.uk-search-navbar .uk-search-icon-flip ~ .uk-search-input {
  padding-right: 40px;
}

/* Large modifier
 ========================================================================== */
.uk-search-large {
  width: 500px;
}

/*
 * Input
 */
.uk-search-large .uk-search-input {
  height: 80px;
  background: transparent;
  font-size: 2.625rem;
}

/*
 * Icon
 */
.uk-search-large .uk-search-icon {
  width: 80px;
}

.uk-search-large .uk-search-icon:not(.uk-search-icon-flip) ~ .uk-search-input {
  padding-left: 80px;
}

.uk-search-large .uk-search-icon-flip ~ .uk-search-input {
  padding-right: 80px;
}

/* Toggle
 ========================================================================== */
.uk-search-toggle {
  color: #999;
}

/* Hover + Focus */
.uk-search-toggle:hover,
.uk-search-toggle:focus {
  color: #666;
}

/* ========================================================================
   Component: Drop
 ========================================================================== */
/*
 * 1. Hide by default
 * 2. Set position
 * 3. Set a default width
 */
.uk-drop {
  /* 1 */
  display: none;
  /* 2 */
  position: absolute;
  z-index: 1020;
  /* 3 */
  box-sizing: border-box;
  width: auto;
}

/* Show */
.uk-drop.uk-open {
  display: block;
}

/* Direction / Alignment modifiers
 ========================================================================== */
/* Direction */
[class*=uk-drop-top] {
  margin-top: -20px;
}

[class*=uk-drop-bottom] {
  margin-top: 20px;
}

[class*=uk-drop-left] {
  margin-left: -20px;
}

[class*=uk-drop-right] {
  margin-left: 20px;
}

/* Grid modifiers
 ========================================================================== */
.uk-drop-stack .uk-drop-grid > * {
  width: 100% !important;
}

/* ========================================================================
   Component: Modal
 ========================================================================== */
/*
 * 1. Hide by default
 * 2. Set position
 * 3. Allow scrolling for the modal dialog
 * 4. Horizontal padding
 * 5. Mask the background page
 * 6. Fade-in transition
 */
.uk-modal {
  /* 1 */
  display: none;
  /* 2 */
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1010;
  /* 3 */
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  /* 4 */
  padding: 15px 15px;
  /* 5 */
  background: linear-gradient(180deg, rgba(0, 174, 164, 0.9) 0%, rgba(2, 140, 132, 0.9) 100%);
  /* 6 */
  opacity: 0;
  transition: opacity 0.15s linear;
}

/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-modal {
    padding: 50px 30px;
  }
}
/* Tablet landscape and bigger */
@media (min-width: 900px) {
  .uk-modal {
    padding-left: 40px;
    padding-right: 40px;
  }
}
/*
 * Open
 */
.uk-modal.uk-open {
  opacity: 1;
}

/* Page
 ========================================================================== */
/*
 * Prevent scrollbars
 */
.uk-modal-page {
  overflow: hidden;
}

/* Dialog
 ========================================================================== */
/*
 * 1. Create position context for spinner and close button
 * 2. Dimensions
 * 3. Fix `max-width: 100%` not working in combination with flex and responsive images in IE11
 *    `!important` needed to overwrite `uk-width-auto`. See `#modal-media-image` in tests
 * 4. Style
 * 5. Slide-in transition
 */
.uk-modal-dialog {
  /* 1 */
  position: relative;
  /* 2 */
  box-sizing: border-box;
  margin: 0 auto;
  width: 880px;
  /* 3 */
  max-width: calc(100% - 0.01px) !important;
  /* 4 */
  background: #fff;
  /* 5 */
  opacity: 0;
  transform: translateY(-100px);
  transition: 0.3s linear;
  transition-property: opacity, transform;
}

/*
 * Open
 */
.uk-open > .uk-modal-dialog {
  opacity: 1;
  transform: translateY(0);
}

/* Size modifier
 ========================================================================== */
/*
 * Container size
 * Take the same size as the Container component
 */
.uk-modal-container .uk-modal-dialog {
  width: 1200px;
}

/*
 * Full size
 * 1. Remove padding and background from modal
 * 2. Reset all default declarations from modal dialog
 */
/* 1 */
.uk-modal-full {
  padding: 0;
  background: none;
}

/* 2 */
.uk-modal-full .uk-modal-dialog {
  margin: 0;
  width: 100%;
  max-width: 100%;
  transform: translateY(0);
}

/* Sections
 ========================================================================== */
.uk-modal-body {
  padding: 40px 40px;
}
@media (max-width: 639px) {
  .uk-modal-body {
    padding: 25px;
  }
}

.uk-modal-header {
  padding: 15px 30px;
  background: #f8f8f8;
}

.uk-modal-footer {
  padding: 15px 30px;
  background: #f8f8f8;
}

/*
 * Micro clearfix
 */
.uk-modal-body::before,
.uk-modal-body::after,
.uk-modal-header::before,
.uk-modal-header::after,
.uk-modal-footer::before,
.uk-modal-footer::after {
  content: "";
  display: table;
}

.uk-modal-body::after,
.uk-modal-header::after,
.uk-modal-footer::after {
  clear: both;
}

/*
 * Remove margin from the last-child
 */
.uk-modal-body > :last-child,
.uk-modal-header > :last-child,
.uk-modal-footer > :last-child {
  margin-bottom: 0;
}

/* Title
 ========================================================================== */
.uk-modal-title {
  font-size: 2rem;
  line-height: 1.3;
}

/* Close
 * Adopts `uk-close`
 ========================================================================== */
[class*=uk-modal-close-] {
  position: absolute;
  z-index: 1010;
  top: 10px;
  right: 10px;
  padding: 5px;
}

/*
 * Remove margin from adjacent element
 */
[class*=uk-modal-close-]:first-child + * {
  margin-top: 0;
}

/*
 * Hover
 */
/*
 * Default
 */
/*
 * Outside
 * 1. Prevent scrollbar on small devices
 */
.uk-modal-close-outside {
  top: 0;
  /* 1 */
  right: -5px;
  transform: translate(0, -100%);
  color: white;
}

.uk-modal-close-outside:hover {
  color: #fff;
}

/* Tablet landscape and bigger */
@media (min-width: 900px) {
  /* 1 */
  .uk-modal-close-outside {
    right: 0;
    transform: translate(100%, -100%);
  }
}
/*
 * Full
 */
/* ========================================================================
   Component: Lightbox
 ========================================================================== */
/*
 * 1. Hide by default
 * 2. Set position
 * 3. Allow scrolling for the modal dialog
 * 4. Horizontal padding
 * 5. Mask the background page
 * 6. Fade-in transition
 * 7. Prevent cancellation of pointer events while dragging
 */
.uk-lightbox {
  /* 1 */
  display: none;
  /* 2 */
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1010;
  /* 5 */
  background: #000;
  /* 6 */
  opacity: 0;
  transition: opacity 0.15s linear;
  /* 7 */
  touch-action: pinch-zoom;
}

/*
 * Open
 * 1. Center child
 * 2. Fade-in
 */
.uk-lightbox.uk-open {
  display: block;
  /* 2 */
  opacity: 1;
}

/* Page
 ========================================================================== */
/*
 * Prevent scrollbars
 */
.uk-lightbox-page {
  overflow: hidden;
}

/* Item
 ========================================================================== */
/*
 * 1. Center child within the viewport
 * 2. Not visible by default
 * 3. Color needed for spinner icon
 * 4. Optimize animation
 * 5. Responsiveness
 *    Using `vh` for `max-height` to fix image proportions after resize in Safari and Opera
 *    Using `vh` and `vw` to make responsive image work in IE11
 * 6. Suppress outline on focus
 */
.uk-lightbox-items > * {
  /* 1 */
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  /* 2 */
  display: none;
  justify-content: center;
  align-items: center;
  /* 3 */
  color: rgba(255, 255, 255, 0.7);
  /* 4 */
  will-change: transform, opacity;
}

/* 5 */
.uk-lightbox-items > * > * {
  max-width: 100vw;
  max-height: 100vh;
}

/* 6 */
.uk-lightbox-items > :focus {
  outline: none;
}

.uk-lightbox-items > * > :not(iframe) {
  width: auto;
  height: auto;
}

.uk-lightbox-items > .uk-active {
  display: flex;
}

/* Toolbar
 ========================================================================== */
.uk-lightbox-toolbar {
  padding: 10px 10px;
  background: rgba(0, 0, 0, 0.3);
  color: rgba(255, 255, 255, 0.7);
}

.uk-lightbox-toolbar > * {
  color: rgba(255, 255, 255, 0.7);
}

/* Toolbar Icon (Close)
 ========================================================================== */
.uk-lightbox-toolbar-icon {
  padding: 5px;
  color: rgba(255, 255, 255, 0.7);
}

/*
 * Hover
 */
.uk-lightbox-toolbar-icon:hover {
  color: #fff;
}

/* Button (Slidenav)
 ========================================================================== */
/*
 * 1. Center icon vertically and horizontally
 */
.uk-lightbox-button {
  box-sizing: border-box;
  width: 50px;
  height: 50px;
  background: rgba(0, 0, 0, 0.3);
  color: rgba(255, 255, 255, 0.7);
  /* 1 */
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

/*
 * Hover
 */
.uk-lightbox-button:hover {
  color: #fff;
}

/* Caption
 ========================================================================== */
.uk-lightbox-caption:empty {
  display: none;
}

/* Iframe
 ========================================================================== */
.uk-lightbox-iframe {
  width: 80%;
  height: 80%;
}

/* ========================================================================
   Component: Off-canvas
 ========================================================================== */
/*
 * 1. Hide by default
 * 2. Set position
 */
.uk-offcanvas {
  /* 1 */
  display: none;
  /* 2 */
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
}

/*
 * Flip modifier
 */
.uk-offcanvas-flip .uk-offcanvas {
  right: 0;
  left: auto;
}

/* Bar
 ========================================================================== */
/*
 * 1. Set position
 * 2. Size and style
 * 3. Allow scrolling
 */
.uk-offcanvas-bar {
  /* 1 */
  position: absolute;
  top: 0;
  bottom: 0;
  left: -100%;
  /* 2 */
  box-sizing: border-box;
  width: 100%;
  padding: 20px 20px;
  background: #ffffff;
  /* 3 */
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

/* Tablet landscape and bigger */
@media (min-width: 900px) {
  .uk-offcanvas-bar {
    left: -350px;
    width: 350px;
    padding: 40px 40px;
  }
}
/* Flip modifier */
.uk-offcanvas-flip .uk-offcanvas-bar {
  left: auto;
  right: -100%;
}

/* Tablet landscape and bigger */
@media (min-width: 900px) {
  .uk-offcanvas-flip .uk-offcanvas-bar {
    right: -350px;
  }
}
/*
 * Open
 */
.uk-open > .uk-offcanvas-bar {
  left: 0;
}

.uk-offcanvas-flip .uk-open > .uk-offcanvas-bar {
  left: auto;
  right: 0;
}

/*
 * Slide Animation (Used in slide and push mode)
 */
.uk-offcanvas-bar-animation {
  transition: left 0.3s ease-out;
}

.uk-offcanvas-flip .uk-offcanvas-bar-animation {
  transition-property: right;
}

/*
 * Reveal Animation
 * 1. Set position
 * 2. Clip the bar
 * 3. Animation
 * 4. Reset position
 */
.uk-offcanvas-reveal {
  /* 1 */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  /* 2 */
  width: 0;
  overflow: hidden;
  /* 3 */
  transition: width 0.3s ease-out;
}

.uk-offcanvas-reveal .uk-offcanvas-bar {
  /* 4 */
  left: 0;
}

.uk-offcanvas-flip .uk-offcanvas-reveal .uk-offcanvas-bar {
  /* 4 */
  left: auto;
  right: 0;
}

.uk-open > .uk-offcanvas-reveal {
  width: 100%;
}

/* Tablet landscape and bigger */
@media (min-width: 900px) {
  .uk-open > .uk-offcanvas-reveal {
    width: 350px;
  }
}
/*
 * Flip modifier
 */
.uk-offcanvas-flip .uk-offcanvas-reveal {
  right: 0;
  left: auto;
}

/* Close
 * Adopts `uk-close`
 ========================================================================== */
.uk-offcanvas-close {
  position: absolute;
  z-index: 1000;
  top: 20px;
  right: 20px;
  padding: 5px;
}

/* Overlay
 ========================================================================== */
/*
 * Overlay the whole page. Needed for the `::before`
 * 1. Using `100vw` so no modification is needed when off-canvas is flipped
 * 2. Allow for closing with swipe gesture on devices with pointer events.
 */
.uk-offcanvas-overlay {
  /* 1 */
  width: 100vw;
  /* 2 */
  touch-action: none;
}

/*
 * 1. Mask the whole page
 * 2. Fade-in transition
 */
.uk-offcanvas-overlay::before {
  /* 1 */
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(180deg, rgba(0, 174, 164, 0.9) 0%, rgba(2, 140, 132, 0.9) 100%);
  /* 2 */
  opacity: 0;
  transition: opacity 0.15s linear;
}

.uk-offcanvas-overlay.uk-open::before {
  opacity: 1;
}

/* Prevent scrolling
 ========================================================================== */
/*
 * Prevent horizontal scrollbar when the content is slide-out
 * Has to be on the `html` element too to make it work on the `body`
 */
.uk-offcanvas-page,
.uk-offcanvas-container {
  overflow-x: hidden;
}

/* Container
 ========================================================================== */
/*
 * Prepare slide-out animation (Used in reveal and push mode)
 * Using `position: left` instead of `transform` because position `fixed` elements like sticky navbars
 * lose their fixed state and behaves like `absolute` within a transformed container
 * 1. Provide a fixed width and prevent shrinking
 */
.uk-offcanvas-container {
  position: relative;
  left: 0;
  transition: left 0.3s ease-out;
  /* 1 */
  box-sizing: border-box;
  width: 100%;
}

/*
 * Activate slide-out animation
 */
:not(.uk-offcanvas-flip).uk-offcanvas-container-animation {
  left: 100%;
}

.uk-offcanvas-flip.uk-offcanvas-container-animation {
  left: -100%;
}

/* Tablet landscape and bigger */
@media (min-width: 900px) {
  :not(.uk-offcanvas-flip).uk-offcanvas-container-animation {
    left: 350px;
  }

  .uk-offcanvas-flip.uk-offcanvas-container-animation {
    left: -350px;
  }
}
@media (min-width: 425px) {
  .uk-offcanvas-bar {
    left: -400px;
    width: 400px;
    padding: 40px 40px;
  }
}
@media (min-width: 640px) {
  .uk-offcanvas-flip .uk-offcanvas-bar {
    right: -400px;
  }
}
/* ========================================================================
   Component: Animation
 ========================================================================== */
[class*=uk-animation-] {
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
  animation-fill-mode: both;
}

/* Animations
 ========================================================================== */
/*
 * Fade
 */
.uk-animation-fade {
  animation-name: uk-fade;
  animation-duration: 0.8s;
  animation-timing-function: linear;
}

/*
 * Scale
 */
.uk-animation-scale-up {
  animation-name: uk-fade-scale-02;
}

.uk-animation-scale-down {
  animation-name: uk-fade-scale-18;
}

/*
 * Slide
 */
.uk-animation-slide-top {
  animation-name: uk-fade-top;
}

.uk-animation-slide-bottom {
  animation-name: uk-fade-bottom;
}

.uk-animation-slide-left {
  animation-name: uk-fade-left;
}

.uk-animation-slide-right {
  animation-name: uk-fade-right;
}

/*
 * Slide Small
 */
.uk-animation-slide-top-small {
  animation-name: uk-fade-top-small;
}

.uk-animation-slide-bottom-small {
  animation-name: uk-fade-bottom-small;
}

.uk-animation-slide-left-small {
  animation-name: uk-fade-left-small;
}

.uk-animation-slide-right-small {
  animation-name: uk-fade-right-small;
}

/*
 * Slide Medium
 */
.uk-animation-slide-top-medium {
  animation-name: uk-fade-top-medium;
}

.uk-animation-slide-bottom-medium {
  animation-name: uk-fade-bottom-medium;
}

.uk-animation-slide-left-medium {
  animation-name: uk-fade-left-medium;
}

.uk-animation-slide-right-medium {
  animation-name: uk-fade-right-medium;
}

/*
 * Kenburns
 */
.uk-animation-kenburns {
  animation-name: uk-scale-kenburns;
  animation-duration: 15s;
}

/*
 * Shake
 */
.uk-animation-shake {
  animation-name: uk-shake;
}

/*
 * SVG Stroke
 * The `--uk-animation-stroke` custom property contains the longest path length.
 * Set it manually or use `uk-svg="stroke-animation: true"` to set it automatically.
 * All strokes are animated by the same pace and doesn't end simultaneously.
 * To end simultaneously, `pathLength="1"` could be used, but it's not working in Safari yet.
 */
.uk-animation-stroke {
  animation-name: uk-stroke;
  stroke-dasharray: var(--uk-animation-stroke);
  animation-duration: 2s;
}

/* Direction modifier
 ========================================================================== */
.uk-animation-reverse {
  animation-direction: reverse;
  animation-timing-function: ease-in;
}

/* Duration modifier
 ========================================================================== */
.uk-animation-fast {
  animation-duration: 0.1s;
}

/* Toggle (Hover + Focus)
========================================================================== */
/*
 * The toggle is triggered on touch devices using `:focus` and tabindex
 */
.uk-animation-toggle:not(:hover):not(:focus) [class*=uk-animation-] {
  animation-name: none;
}

/*
 * 1. Prevent tab highlighting on iOS.
 */
.uk-animation-toggle {
  /* 1 */
  -webkit-tap-highlight-color: transparent;
}

/*
 * Remove outline for `tabindex`
 */
.uk-animation-toggle:focus {
  outline: none;
}

/* Keyframes used by animation classes
 ========================================================================== */
/*
 * Fade
 */
@keyframes uk-fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
/*
 * Slide Top
 */
@keyframes uk-fade-top {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
/*
 * Slide Bottom
 */
@keyframes uk-fade-bottom {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
/*
 * Slide Left
 */
@keyframes uk-fade-left {
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
/*
 * Slide Right
 */
@keyframes uk-fade-right {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
/*
 * Slide Top Small
 */
@keyframes uk-fade-top-small {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
/*
 * Slide Bottom Small
 */
@keyframes uk-fade-bottom-small {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
/*
 * Slide Left Small
 */
@keyframes uk-fade-left-small {
  0% {
    opacity: 0;
    transform: translateX(-10px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
/*
 * Slide Right Small
 */
@keyframes uk-fade-right-small {
  0% {
    opacity: 0;
    transform: translateX(10px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
/*
 * Slide Top Medium
 */
@keyframes uk-fade-top-medium {
  0% {
    opacity: 0;
    transform: translateY(-50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
/*
 * Slide Bottom Medium
 */
@keyframes uk-fade-bottom-medium {
  0% {
    opacity: 0;
    transform: translateY(50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
/*
 * Slide Left Medium
 */
@keyframes uk-fade-left-medium {
  0% {
    opacity: 0;
    transform: translateX(-50px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
/*
 * Slide Right Medium
 */
@keyframes uk-fade-right-medium {
  0% {
    opacity: 0;
    transform: translateX(50px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
/*
 * Scale Up
 */
@keyframes uk-fade-scale-02 {
  0% {
    opacity: 0;
    transform: scale(0.2);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
/*
 * Scale Down
 */
@keyframes uk-fade-scale-18 {
  0% {
    opacity: 0;
    transform: scale(1.8);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
/*
 * Kenburns
 */
@keyframes uk-scale-kenburns {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.2);
  }
}
/*
 * Shake
 */
@keyframes uk-shake {
  0%, 100% {
    transform: translateX(0);
  }
  10% {
    transform: translateX(-9px);
  }
  20% {
    transform: translateX(8px);
  }
  30% {
    transform: translateX(-7px);
  }
  40% {
    transform: translateX(6px);
  }
  50% {
    transform: translateX(-5px);
  }
  60% {
    transform: translateX(4px);
  }
  70% {
    transform: translateX(-3px);
  }
  80% {
    transform: translateX(2px);
  }
  90% {
    transform: translateX(-1px);
  }
}
/*
 * Stroke
 */
@keyframes uk-stroke {
  0% {
    stroke-dashoffset: var(--uk-animation-stroke);
  }
  100% {
    stroke-dashoffset: 0;
  }
}
/* ========================================================================
   Component: Text
 ========================================================================== */
/* Style modifiers
 ========================================================================== */
.uk-text-lead {
  font-size: 1.5rem;
  line-height: 1.5;
  color: #333;
}

.uk-text-meta {
  font-size: 0.875rem;
  line-height: 1.4;
  color: #999;
}

/* Size modifiers
 ========================================================================== */
.uk-text-small {
  font-size: 0.875rem;
  line-height: 1.5;
}

.uk-text-large {
  font-size: 1.5rem;
  line-height: 1.5;
}

/* Weight modifier
 ========================================================================== */
.uk-text-light {
  font-weight: 300;
}

.uk-text-normal {
  font-weight: 400;
}

.uk-text-bold {
  font-weight: 700;
}

.uk-text-lighter {
  font-weight: lighter;
}

.uk-text-bolder {
  font-weight: bolder;
}

/* Style modifier
 ========================================================================== */
.uk-text-italic {
  font-style: italic;
}

/* Transform modifier
 ========================================================================== */
.uk-text-capitalize {
  text-transform: capitalize !important;
}

.uk-text-uppercase {
  text-transform: uppercase !important;
}

.uk-text-lowercase {
  text-transform: lowercase !important;
}

/* Color modifiers
 ========================================================================== */
.uk-text-muted {
  color: #999 !important;
}

.uk-text-emphasis {
  color: #333 !important;
}

.uk-text-primary {
  color: #1e87f0 !important;
}

.uk-text-secondary {
  color: #222 !important;
}

.uk-text-success {
  color: #32d296 !important;
}

.uk-text-warning {
  color: #faa05a !important;
}

.uk-text-danger {
  color: #f0506e !important;
}

/* Background modifier
 ========================================================================== */
/*
 * 1. The background clips to the foreground text. Works in Chrome, Firefox, Safari, Edge and Opera
 *    Default color is set to transparent
 * 2. Container fits the text
 * 3. Fallback color for IE11
 */
.uk-text-background {
  /* 1 */
  -webkit-background-clip: text;
  /* 2 */
  display: inline-block;
  /* 3 */
  color: #1e87f0 !important;
}

@supports (-webkit-background-clip: text) {
  .uk-text-background {
    background-color: #1e87f0;
    color: transparent !important;
  }
}
/* Alignment modifiers
 ========================================================================== */
.uk-text-left {
  text-align: left !important;
}

.uk-text-right {
  text-align: right !important;
}

.uk-text-center {
  text-align: center !important;
}

.uk-text-justify {
  text-align: justify !important;
}

/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-text-left\@s {
    text-align: left !important;
  }

  .uk-text-right\@s {
    text-align: right !important;
  }

  .uk-text-center\@s {
    text-align: center !important;
  }
}
/* Tablet landscape and bigger */
@media (min-width: 900px) {
  .uk-text-left\@m {
    text-align: left !important;
  }

  .uk-text-right\@m {
    text-align: right !important;
  }

  .uk-text-center\@m {
    text-align: center !important;
  }
}
/* Desktop and bigger */
@media (min-width: 1000px) {
  .uk-text-left\@l {
    text-align: left !important;
  }

  .uk-text-right\@l {
    text-align: right !important;
  }

  .uk-text-center\@l {
    text-align: center !important;
  }
}
/* Large screen and bigger */
@media (min-width: 1200px) {
  .uk-text-left\@xl {
    text-align: left !important;
  }

  .uk-text-right\@xl {
    text-align: right !important;
  }

  .uk-text-center\@xl {
    text-align: center !important;
  }
}
/*
 * Vertical
 */
.uk-text-top {
  vertical-align: top !important;
}

.uk-text-middle {
  vertical-align: middle !important;
}

.uk-text-bottom {
  vertical-align: bottom !important;
}

.uk-text-baseline {
  vertical-align: baseline !important;
}

/* Wrap modifiers
 ========================================================================== */
/*
 * Prevent text from wrapping onto multiple lines
 */
.uk-text-nowrap {
  white-space: nowrap;
}

/*
 * 1. Make sure a max-width is set after which truncation can occur
 * 2. Prevent text from wrapping onto multiple lines, and truncate with an ellipsis
 * 3. Fix for table cells
 */
.uk-text-truncate {
  /* 1 */
  max-width: 100%;
  /* 2 */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* 2 */
th.uk-text-truncate,
td.uk-text-truncate {
  max-width: 0;
}

/*
 * 1. Wrap long words onto the next line and break them if they are too long to fit
 * 2. Legacy `word-wrap` as fallback for `overflow-wrap`
 * 3. Fix `overflow-wrap` which doesn't work with table cells in Chrome, Opera, IE11 and Edge
 *    Must use `break-all` to support IE11 and Edge
 * Note: Not using `hyphens: auto;` because it hyphenates text even if not needed
 */
.uk-text-break {
  /* 1 */
  overflow-wrap: break-word;
  /* 2 */
  word-wrap: break-word;
}

/* 3 */
th.uk-text-break,
td.uk-text-break {
  word-break: break-all;
}

/* ========================================================================
   Component: Flex
 ========================================================================== */
.uk-flex {
  display: flex;
}

.uk-flex-inline {
  display: inline-flex;
}

/*
 * Remove pseudo elements created by micro clearfix as precaution
 */
.uk-flex::before,
.uk-flex::after,
.uk-flex-inline::before,
.uk-flex-inline::after {
  display: none;
}

/* Alignment
 ========================================================================== */
/*
 * Align items along the main axis of the current line of the flex container
 * Row: Horizontal
 */
.uk-flex-left {
  justify-content: flex-start;
}

.uk-flex-center {
  justify-content: center;
}

.uk-flex-right {
  justify-content: flex-end;
}

.uk-flex-between {
  justify-content: space-between;
}

.uk-flex-around {
  justify-content: space-around;
}

/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-flex-left\@s {
    justify-content: flex-start;
  }

  .uk-flex-center\@s {
    justify-content: center;
  }

  .uk-flex-right\@s {
    justify-content: flex-end;
  }

  .uk-flex-between\@s {
    justify-content: space-between;
  }

  .uk-flex-around\@s {
    justify-content: space-around;
  }
}
/* Tablet landscape and bigger */
@media (min-width: 900px) {
  .uk-flex-left\@m {
    justify-content: flex-start;
  }

  .uk-flex-center\@m {
    justify-content: center;
  }

  .uk-flex-right\@m {
    justify-content: flex-end;
  }

  .uk-flex-between\@m {
    justify-content: space-between;
  }

  .uk-flex-around\@m {
    justify-content: space-around;
  }
}
/* Desktop and bigger */
@media (min-width: 1000px) {
  .uk-flex-left\@l {
    justify-content: flex-start;
  }

  .uk-flex-center\@l {
    justify-content: center;
  }

  .uk-flex-right\@l {
    justify-content: flex-end;
  }

  .uk-flex-between\@l {
    justify-content: space-between;
  }

  .uk-flex-around\@l {
    justify-content: space-around;
  }
}
/* Large screen and bigger */
@media (min-width: 1200px) {
  .uk-flex-left\@xl {
    justify-content: flex-start;
  }

  .uk-flex-center\@xl {
    justify-content: center;
  }

  .uk-flex-right\@xl {
    justify-content: flex-end;
  }

  .uk-flex-between\@xl {
    justify-content: space-between;
  }

  .uk-flex-around\@xl {
    justify-content: space-around;
  }
}
/*
 * Align items in the cross axis of the current line of the flex container
 * Row: Vertical
 */
.uk-flex-stretch {
  align-items: stretch;
}

.uk-flex-top {
  align-items: flex-start;
}

.uk-flex-middle {
  align-items: center;
}

.uk-flex-bottom {
  align-items: flex-end;
}

/* Direction
 ========================================================================== */
.uk-flex-row {
  flex-direction: row;
}

.uk-flex-row-reverse {
  flex-direction: row-reverse;
}

.uk-flex-column {
  flex-direction: column;
}

.uk-flex-column-reverse {
  flex-direction: column-reverse;
}

/* Wrap
 ========================================================================== */
.uk-flex-nowrap {
  flex-wrap: nowrap;
}

.uk-flex-wrap {
  flex-wrap: wrap;
}

.uk-flex-wrap-reverse {
  flex-wrap: wrap-reverse;
}

/*
 * Aligns items within the flex container when there is extra space in the cross-axis
 * Only works if there is more than one line of flex items
 */
.uk-flex-wrap-stretch {
  align-content: stretch;
}

.uk-flex-wrap-top {
  align-content: flex-start;
}

.uk-flex-wrap-middle {
  align-content: center;
}

.uk-flex-wrap-bottom {
  align-content: flex-end;
}

.uk-flex-wrap-between {
  align-content: space-between;
}

.uk-flex-wrap-around {
  align-content: space-around;
}

/* Item ordering
 ========================================================================== */
/*
 * Default is 0
 */
.uk-flex-first {
  order: -1;
}

.uk-flex-last {
  order: 99;
}

/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-flex-first\@s {
    order: -1;
  }

  .uk-flex-last\@s {
    order: 99;
  }
}
/* Tablet landscape and bigger */
@media (min-width: 900px) {
  .uk-flex-first\@m {
    order: -1;
  }

  .uk-flex-last\@m {
    order: 99;
  }
}
/* Desktop and bigger */
@media (min-width: 1000px) {
  .uk-flex-first\@l {
    order: -1;
  }

  .uk-flex-last\@l {
    order: 99;
  }
}
/* Large screen and bigger */
@media (min-width: 1200px) {
  .uk-flex-first\@xl {
    order: -1;
  }

  .uk-flex-last\@xl {
    order: 99;
  }
}
/* Item dimensions
 ========================================================================== */
/*
 * Initial: 0 1 auto
 * Content dimensions, but shrinks
 */
/*
 * No Flex: 0 0 auto
 * Content dimensions
 */
.uk-flex-none {
  flex: none;
}

/*
 * Relative Flex: 1 1 auto
 * Space is allocated considering content
 */
.uk-flex-auto {
  flex: auto;
}

/*
 * Absolute Flex: 1 1 0%
 * Space is allocated solely based on flex
 */
.uk-flex-1 {
  flex: 1;
}

/* ========================================================================
   Component: Margin
 ========================================================================== */
/*
 * Default
 */
.uk-margin {
  margin-bottom: 20px;
}

* + .uk-margin {
  margin-top: 20px !important;
}

.uk-margin-top {
  margin-top: 20px !important;
}

.uk-margin-bottom {
  margin-bottom: 20px !important;
}

.uk-margin-left {
  margin-left: 20px !important;
}

.uk-margin-right {
  margin-right: 20px !important;
}

/* Small
 ========================================================================== */
.uk-margin-small {
  margin-bottom: 10px;
}

* + .uk-margin-small {
  margin-top: 10px !important;
}

.uk-margin-small-top {
  margin-top: 10px !important;
}

.uk-margin-small-bottom {
  margin-bottom: 10px !important;
}

.uk-margin-small-left {
  margin-left: 10px !important;
}

.uk-margin-small-right {
  margin-right: 10px !important;
}

/* Medium
 ========================================================================== */
.uk-margin-medium {
  margin-bottom: 40px;
}

* + .uk-margin-medium {
  margin-top: 40px !important;
}

.uk-margin-medium-top {
  margin-top: 40px !important;
}

.uk-margin-medium-bottom {
  margin-bottom: 40px !important;
}

.uk-margin-medium-left {
  margin-left: 40px !important;
}

.uk-margin-medium-right {
  margin-right: 40px !important;
}

/* Large
 ========================================================================== */
.uk-margin-large {
  margin-bottom: 40px;
}

* + .uk-margin-large {
  margin-top: 40px !important;
}

.uk-margin-large-top {
  margin-top: 40px !important;
}

.uk-margin-large-bottom {
  margin-bottom: 40px !important;
}

.uk-margin-large-left {
  margin-left: 40px !important;
}

.uk-margin-large-right {
  margin-right: 40px !important;
}

/* Desktop and bigger */
@media (min-width: 1000px) {
  .uk-margin-large {
    margin-bottom: 70px;
  }

  * + .uk-margin-large {
    margin-top: 70px !important;
  }

  .uk-margin-large-top {
    margin-top: 70px !important;
  }

  .uk-margin-large-bottom {
    margin-bottom: 70px !important;
  }

  .uk-margin-large-left {
    margin-left: 70px !important;
  }

  .uk-margin-large-right {
    margin-right: 70px !important;
  }
}
/* XLarge
 ========================================================================== */
.uk-margin-xlarge {
  margin-bottom: 70px;
}

* + .uk-margin-xlarge {
  margin-top: 70px !important;
}

.uk-margin-xlarge-top {
  margin-top: 70px !important;
}

.uk-margin-xlarge-bottom {
  margin-bottom: 70px !important;
}

.uk-margin-xlarge-left {
  margin-left: 70px !important;
}

.uk-margin-xlarge-right {
  margin-right: 70px !important;
}

/* Desktop and bigger */
@media (min-width: 1000px) {
  .uk-margin-xlarge {
    margin-bottom: 140px;
  }

  * + .uk-margin-xlarge {
    margin-top: 140px !important;
  }

  .uk-margin-xlarge-top {
    margin-top: 140px !important;
  }

  .uk-margin-xlarge-bottom {
    margin-bottom: 140px !important;
  }

  .uk-margin-xlarge-left {
    margin-left: 140px !important;
  }

  .uk-margin-xlarge-right {
    margin-right: 140px !important;
  }
}
/* Auto
 ========================================================================== */
.uk-margin-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.uk-margin-auto-top {
  margin-top: auto !important;
}

.uk-margin-auto-bottom {
  margin-bottom: auto !important;
}

.uk-margin-auto-left {
  margin-left: auto !important;
}

.uk-margin-auto-right {
  margin-right: auto !important;
}

.uk-margin-auto-vertical {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-margin-auto\@s {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .uk-margin-auto-left\@s {
    margin-left: auto !important;
  }

  .uk-margin-auto-right\@s {
    margin-right: auto !important;
  }
}
/* Tablet landscape and bigger */
@media (min-width: 900px) {
  .uk-margin-auto\@m {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .uk-margin-auto-left\@m {
    margin-left: auto !important;
  }

  .uk-margin-auto-right\@m {
    margin-right: auto !important;
  }
}
/* Desktop and bigger */
@media (min-width: 1000px) {
  .uk-margin-auto\@l {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .uk-margin-auto-left\@l {
    margin-left: auto !important;
  }

  .uk-margin-auto-right\@l {
    margin-right: auto !important;
  }
}
/* Large screen and bigger */
@media (min-width: 1200px) {
  .uk-margin-auto\@xl {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .uk-margin-auto-left\@xl {
    margin-left: auto !important;
  }

  .uk-margin-auto-right\@xl {
    margin-right: auto !important;
  }
}
/* Remove
 ========================================================================== */
.uk-margin-remove {
  margin: 0 !important;
}

.uk-margin-remove-top {
  margin-top: 0 !important;
}

.uk-margin-remove-bottom {
  margin-bottom: 0 !important;
}

.uk-margin-remove-left {
  margin-left: 0 !important;
}

.uk-margin-remove-right {
  margin-right: 0 !important;
}

.uk-margin-remove-vertical {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.uk-margin-remove-adjacent + *,
.uk-margin-remove-first-child > :first-child {
  margin-top: 0 !important;
}

.uk-margin-remove-last-child > :last-child {
  margin-bottom: 0 !important;
}

/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-margin-remove-left\@s {
    margin-left: 0 !important;
  }

  .uk-margin-remove-right\@s {
    margin-right: 0 !important;
  }
}
/* Tablet landscape and bigger */
@media (min-width: 900px) {
  .uk-margin-remove-left\@m {
    margin-left: 0 !important;
  }

  .uk-margin-remove-right\@m {
    margin-right: 0 !important;
  }
}
/* Desktop and bigger */
@media (min-width: 1000px) {
  .uk-margin-remove-left\@l {
    margin-left: 0 !important;
  }

  .uk-margin-remove-right\@l {
    margin-right: 0 !important;
  }
}
/* Large screen and bigger */
@media (min-width: 1200px) {
  .uk-margin-remove-left\@xl {
    margin-left: 0 !important;
  }

  .uk-margin-remove-right\@xl {
    margin-right: 0 !important;
  }
}
/* ========================================================================
   Component: Position
 ========================================================================== */
/* Directions
 ========================================================================== */
/*
 * 1. Prevent content overflow if `max-width: 100%` is used inside position container.
 */
[class*=uk-position-top],
[class*=uk-position-bottom],
[class*=uk-position-left],
[class*=uk-position-right],
[class*=uk-position-center] {
  position: absolute !important;
  /* 1 */
  max-width: 100%;
}

/* Edges
 ========================================================================== */
/* Don't use `width: 100%` because it is wrong if the parent has padding. */
.uk-position-top {
  top: 0;
  left: 0;
  right: 0;
}

.uk-position-bottom {
  bottom: 0;
  left: 0;
  right: 0;
}

.uk-position-left {
  top: 0;
  bottom: 0;
  left: 0;
}

.uk-position-right {
  top: 0;
  bottom: 0;
  right: 0;
}

/* Corners
 ========================================================================== */
.uk-position-top-left {
  top: 0;
  left: 0;
}

.uk-position-top-right {
  top: 0;
  right: 0;
}

.uk-position-bottom-left {
  bottom: 0;
  left: 0;
}

.uk-position-bottom-right {
  bottom: 0;
  right: 0;
}

/*
 * Center
 * 1. Fix text wrapping if content is larger than 50% of the container.
 */
.uk-position-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* 1 */
  width: max-content;
  max-width: 100%;
  box-sizing: border-box;
}

/* Vertical */
[class*=uk-position-center-left],
[class*=uk-position-center-right] {
  top: 50%;
  transform: translateY(-50%);
}

.uk-position-center-left {
  left: 0;
}

.uk-position-center-right {
  right: 0;
}

.uk-position-center-left-out {
  right: 100%;
  width: max-content;
}

.uk-position-center-right-out {
  left: 100%;
  width: max-content;
}

/* Horizontal */
.uk-position-top-center,
.uk-position-bottom-center {
  left: 50%;
  transform: translateX(-50%);
  /* 1 */
  width: max-content;
  max-width: 100%;
  box-sizing: border-box;
}

.uk-position-top-center {
  top: 0;
}

.uk-position-bottom-center {
  bottom: 0;
}

/* Cover
 ========================================================================== */
.uk-position-cover {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

/* Utility
 ========================================================================== */
.uk-position-relative {
  position: relative !important;
}

.uk-position-absolute {
  position: absolute !important;
}

.uk-position-fixed {
  position: fixed !important;
}

.uk-position-z-index {
  z-index: 1;
}

/* Margin modifier
 ========================================================================== */
/*
 * Small
 */
.uk-position-small {
  max-width: calc(100% - (15px * 2));
  margin: 15px;
}

.uk-position-small.uk-position-center {
  transform: translate(-50%, -50%) translate(-15px, -15px);
}

.uk-position-small[class*=uk-position-center-left],
.uk-position-small[class*=uk-position-center-right] {
  transform: translateY(-50%) translateY(-15px);
}

.uk-position-small.uk-position-top-center,
.uk-position-small.uk-position-bottom-center {
  transform: translateX(-50%) translateX(-15px);
}

/*
 * Medium
 */
.uk-position-medium {
  max-width: calc(100% - (30px * 2));
  margin: 30px;
}

.uk-position-medium.uk-position-center {
  transform: translate(-50%, -50%) translate(-30px, -30px);
}

.uk-position-medium[class*=uk-position-center-left],
.uk-position-medium[class*=uk-position-center-right] {
  transform: translateY(-50%) translateY(-30px);
}

.uk-position-medium.uk-position-top-center,
.uk-position-medium.uk-position-bottom-center {
  transform: translateX(-50%) translateX(-30px);
}

/*
 * Large
 */
.uk-position-large {
  max-width: calc(100% - (30px * 2));
  margin: 30px;
}

.uk-position-large.uk-position-center {
  transform: translate(-50%, -50%) translate(-30px, -30px);
}

.uk-position-large[class*=uk-position-center-left],
.uk-position-large[class*=uk-position-center-right] {
  transform: translateY(-50%) translateY(-30px);
}

.uk-position-large.uk-position-top-center,
.uk-position-large.uk-position-bottom-center {
  transform: translateX(-50%) translateX(-30px);
}

/* Desktop and bigger */
@media (min-width: 1000px) {
  .uk-position-large {
    max-width: calc(100% - (50px * 2));
    margin: 50px;
  }

  .uk-position-large.uk-position-center {
    transform: translate(-50%, -50%) translate(-50px, -50px);
  }

  .uk-position-large[class*=uk-position-center-left],
.uk-position-large[class*=uk-position-center-right] {
    transform: translateY(-50%) translateY(-50px);
  }

  .uk-position-large.uk-position-top-center,
.uk-position-large.uk-position-bottom-center {
    transform: translateX(-50%) translateX(-50px);
  }
}
/* ========================================================================
   Component: Visibility
 ========================================================================== */
/*
 * Hidden
 * `hidden` attribute also set here to make it stronger
 */
[hidden],
.uk-hidden {
  display: none !important;
}

/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-hidden\@s {
    display: none !important;
  }
}
/* Tablet landscape and bigger */
@media (min-width: 900px) {
  .uk-hidden\@m {
    display: none !important;
  }
}
/* Desktop and bigger */
@media (min-width: 1000px) {
  .uk-hidden\@l {
    display: none !important;
  }
}
/* Large screen and bigger */
@media (min-width: 1200px) {
  .uk-hidden\@xl {
    display: none !important;
  }
}
/*
 * Visible
 */
/* Phone portrait and smaller */
@media (max-width: 639px) {
  .uk-visible\@s {
    display: none !important;
  }
}
/* Phone landscape and smaller */
@media (max-width: 899px) {
  .uk-visible\@m {
    display: none !important;
  }
}
/* Tablet landscape and smaller */
@media (max-width: 999px) {
  .uk-visible\@l {
    display: none !important;
  }
}
/* Desktop and smaller */
@media (max-width: 1199px) {
  .uk-visible\@xl {
    display: none !important;
  }
}
/* Visibility
 ========================================================================== */
.uk-invisible {
  visibility: hidden !important;
}

/* Toggle (Hover + Focus)
 ========================================================================== */
/*
 * Hidden
 * 1. The toggle is triggered on touch devices using `:focus` and tabindex
 * 2. The target stays visible if any element within receives focus through keyboard
 *    Doesn't work in Edge, yet.
 * 3. Can't use `display: none` nor `visibility: hidden` because both are not focusable.
 *
 */
/* 1 + 2 */
.uk-visible-toggle:not(:hover):not(:focus) .uk-hidden-hover:not(:focus-within) {
  /* 3 */
  position: absolute !important;
  width: 0 !important;
  height: 0 !important;
  padding: 0 !important;
  margin: 0 !important;
  overflow: hidden !important;
}

/*
 * Invisible
 */
/* 1 + 2 */
.uk-visible-toggle:not(:hover):not(:focus) .uk-invisible-hover:not(:focus-within) {
  /* 3 */
  opacity: 0 !important;
}

/*
 * 1. Prevent tab highlighting on iOS.
 */
.uk-visible-toggle {
  /* 1 */
  -webkit-tap-highlight-color: transparent;
}

/*
 * Remove outline for `tabindex`
 */
.uk-visible-toggle:focus {
  outline: none;
}

/* Touch
 ========================================================================== */
/*
 * Hide if primary pointing device has limited accuracy, e.g. a touch screen.
 * Works on mobile browsers: Safari, Chrome and Android browser
 */
@media (pointer: coarse) {
  .uk-hidden-touch {
    display: none !important;
  }
}
/*
 * Hide if primary pointing device is accurate, e.g. mouse.
 * 1. Fallback for IE11 and Firefox, because `pointer` is not supported
 * 2. Reset if supported
 */
/* 1 */
.uk-hidden-notouch {
  display: none !important;
}

@media (pointer: coarse) {
  .uk-hidden-notouch {
    display: block !important;
  }
}
/* ========================================================================
   Component: Print
 ========================================================================== */
@media print {
  *,
*::before,
*::after {
    background: transparent !important;
    color: black !important;
    box-shadow: none !important;
    text-shadow: none !important;
  }

  a,
a:visited {
    text-decoration: underline;
  }

  pre,
blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
  }

  thead {
    display: table-header-group;
  }

  tr,
img {
    page-break-inside: avoid;
  }

  img {
    max-width: 100% !important;
  }

  @page {
    margin: 0.5cm;
  }
  p,
h2,
h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
h3 {
    page-break-after: avoid;
  }
}
.Content {
  font-size: 1rem;
}
html:root .Content > *:first-child {
  margin-top: 0;
}
.Content img {
  height: auto !important;
  margin-bottom: 28px;
}
.Content p {
  margin-bottom: 16px;
}
@media (max-width: 899px) {
  .Content p {
    margin-bottom: 12px;
  }
}
.Content p:last-child {
  margin-bottom: 0;
}
.Content a {
  text-decoration: underline;
}
.Content em {
  color: unset;
}
.Content ul li,
.Content ol li {
  position: relative;
  margin-y: 8px;
  padding-left: 22px;
}
@media (max-width: 639px) {
  .Content ul li,
.Content ol li {
    margin: 8px 0;
  }
}
.Content ul li::before,
.Content ol li::before {
  position: absolute;
  left: 0;
}
.Content ul li:last-of-type,
.Content ol li:last-of-type {
  margin-bottom: 0;
}
.Content ul,
.Content ol,
.Content blockquote {
  margin: 24px 0;
}
@media (max-width: 639px) {
  .Content ul,
.Content ol,
.Content blockquote {
    margin: 16px 0;
  }
}
.Content ul:last-child,
.Content ol:last-child,
.Content blockquote:last-child {
  margin-bottom: 0;
}
.Content ul {
  --unordered-list-bullet-color: #bd2056;
  --unordered-list-bullet-border-color: #bd2056;
}
.Content ul li::before {
  background-color: var(--unordered-list-bullet-color);
  border: 1px solid var(--unordered-list-bullet-border-color);
  border-radius: 50%;
  content: "";
  size: 7px;
  top: 7px;
}
.Content ul li > ul {
  --unordered-list-bullet-color: white;
  --unordered-list-bullet-border-color: #d32460;
  margin-left: 12px;
}
.Content ol {
  counter-reset: list-counter;
}
.Content ol li {
  counter-increment: list-counter;
}
.Content ol li::before {
  content: counter(list-counter) ".";
  top: 0;
  font-size: inherit;
  font-weight: 500;
}

.SiteForm {
  border-radius: 10px;
}
@media (max-width: 639px) {
  .SiteForm__column:not(:last-child) {
    margin-bottom: 12px;
  }
}
.SiteForm__container {
  display: flex;
  align-items: center;
}
@media (max-width: 639px) {
  .SiteForm__container {
    flex-wrap: wrap;
  }
}
.SiteForm__container--subscribe {
  justify-content: center;
  flex-wrap: wrap;
}
.SiteForm__block:not(:last-child) {
  margin-bottom: 16px;
}
@media (max-width: 424px) {
  .SiteForm__block:not(:last-child) {
    margin-bottom: 12px;
  }
}
.SiteForm__block--subscribe:not(:last-child) {
  margin-bottom: 0;
}
@media (max-width: 639px) {
  .SiteForm__block--subscribe:not(:last-child) {
    margin-bottom: 12px;
  }
}
.SiteForm__block--file {
  width: 100%;
}
.SiteForm__block--file:not(:last-child) {
  margin-bottom: 42px;
}
.SiteForm__block--heightExpand {
  height: 100%;
}
.SiteForm__field {
  display: flex;
  flex-wrap: wrap;
  height: inherit;
}
.SiteForm__field--file {
  align-items: center;
}
.SiteForm__grid {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 24px;
}
@media (max-width: 639px) {
  .SiteForm__grid {
    grid-template-columns: 1fr;
    grid-gap: 0;
  }
}
.SiteForm__grid--3column {
  grid-template-columns: repeat(3, 1fr);
}
@media (max-width: 639px) {
  .SiteForm__grid--3column {
    grid-template-columns: 1fr;
    grid-gap: 0;
  }
}
.SiteForm__heading {
  margin-bottom: 32px;
}
@media (max-width: 369px) {
  .SiteForm__heading {
    margin-bottom: 20px;
  }
}
.SiteForm__heading--subscribe {
  width: 100%;
  text-align: center;
}
.SiteForm__motivation {
  max-width: 420px;
  text-align: center;
  margin-bottom: 32px;
}
.SiteForm__icon {
  transition: opacity 0.4s ease-in-out, fill 0.4s ease-in-out;
}
.SiteForm__icon--attach {
  order: -1;
  position: relative;
  top: -1px;
}
.SiteForm__icon--attachClose {
  fill: #bd2056;
}
@media (hover: hover) {
  .SiteForm__icon--attachClose:hover {
    cursor: pointer;
    fill: #028c84;
  }
  .SiteForm__icon--attachClose:hover ~ .SiteForm__label--file {
    opacity: 0.6;
  }
}
.SiteForm__label {
  font-size: 0.875rem;
  line-height: 1;
  font-weight: 500;
  order: -1;
  margin-bottom: 5px;
  transition: opacity 0.4s ease-in-out, color 0.4s ease-in-out, border-bottom-color 0.4s ease-in-out;
}
@media (hover: hover) {
  .SiteForm__label:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}
.SiteForm__label--file {
  color: #bd2056;
  border-bottom: 1px dashed rgba(189, 32, 86, 0.5);
  margin: 0 12px 0 8px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 290px;
  order: 0;
}
.SiteForm__label--file:hover {
  color: #028c84;
  border-color: #028c84;
}
.SiteForm__label--file:hover + .SiteForm__icon--attach {
  fill: #028c84;
}
.SiteForm__label--file:hover {
  color: #028c84;
  border-color: #028c84;
}
.SiteForm__label--file:hover + .SiteForm__icon--attach {
  fill: #028c84;
}
.SiteForm__input:required + .SiteForm__label::after, .SiteForm__textarea:required + .SiteForm__label::after {
  content: "*";
  color: #bd2056;
}
.SiteForm__input {
  transition: border-color 0.4s ease-in-out;
}
.SiteForm__input--file:active ~ .SiteForm__label--file, .SiteForm__input--file:focus ~ .SiteForm__label--file {
  color: #028c84;
  border-color: #028c84;
}
.SiteForm__input--file:active ~ .SiteForm__label--file + .SiteForm__icon--attach, .SiteForm__input--file:focus ~ .SiteForm__label--file + .SiteForm__icon--attach {
  fill: #028c84;
}
.SiteForm__input--fileAttached ~ .SiteForm__label--file {
  color: #231f20;
  border-bottom: none;
  max-width: calc(100% - 60px);
}
@media (max-width: 424px) {
  .SiteForm__input--fileAttached ~ .SiteForm__label--file {
    width: calc(100% - 60px);
    max-width: 240px;
  }
}
@media (max-width: 369px) {
  .SiteForm__input--fileAttached ~ .SiteForm__label--file {
    max-width: 190px;
  }
}
.SiteForm__input--fileAttached ~ .SiteForm__label--file:hover {
  color: #028c84;
  opacity: 1;
}
.SiteForm__input--fileAttached ~ .SiteForm__icon--attach {
  fill: #231f20;
  opacity: 0.3;
}
.SiteForm__input--fileAttached ~ .SiteForm__buttonAttach {
  opacity: 1;
  z-index: 0;
}
.SiteForm__input--invalid {
  border-color: #bd2056;
}
.SiteForm__input--invalid ~ .SiteForm__error {
  opacity: 0.8;
  display: block;
}
.SiteForm__textarea {
  resize: none;
  height: calc(100% - 19px);
}
@media (max-width: 639px) {
  .SiteForm__textarea {
    height: 160px;
  }
}
.SiteForm__bottom {
  display: flex;
  flex-wrap: wrap;
  grid-column: 1/-1;
  margin-top: 32px;
}
@media (max-width: 424px) {
  .SiteForm__bottom {
    margin-top: 24px;
  }
}
.SiteForm__bottom--rent {
  margin-top: 41px;
}
.SiteForm__agreement {
  color: #471045;
  font-size: 0.75rem;
  line-height: 1.4;
  text-align: left;
  opacity: 0.8;
  max-width: 500px;
}
.SiteForm__buttonAttach {
  opacity: 0;
  z-index: -1;
  order: 4;
  padding: 0;
  line-height: 1;
  background-color: transparent;
}
.SiteForm__buttonAttach:focus ~ .SiteForm__label--file, .SiteForm__buttonAttach:active ~ .SiteForm__label--file, .SiteForm__buttonAttach:hover ~ .SiteForm__label--file {
  opacity: 0.6;
}
.SiteForm__buttonAttach:focus .SiteForm__icon--attachClose, .SiteForm__buttonAttach:active .SiteForm__icon--attachClose, .SiteForm__buttonAttach:hover .SiteForm__icon--attachClose {
  fill: #028c84;
}
.SiteForm__buttonSend {
  margin-right: 32px;
  padding-x: 40px;
}
@media (max-width: 639px) {
  .SiteForm__buttonSend {
    margin-bottom: 12px;
  }
}
.SiteForm__buttonSend--subscribe {
  margin: 0;
}
.SiteForm__policy {
  color: inherit;
}
.SiteForm__policy:hover, .SiteForm__policy:focus, .SiteForm__policy:active {
  color: #028c84;
}
.SiteForm__error {
  margin-top: 5px;
  color: #bd2056;
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 1;
  display: none;
  opacity: 0;
  transition: opacity 0.4s ease-in-out;
}

.ErrorPage__title, .SiteForm__heading {
  font-weight: 700;
}

.ErrorPage__title, .SiteForm__heading {
  font-size: 2rem;
}
@media (max-width: 639px) {
  .ErrorPage__title, .SiteForm__heading {
    font-size: 1.5rem;
  }
}

.ListPagination__link, .PolicyList__value, .EmailsList__value, .PhonesList__value, .VideoPlayer__link, .LinkBack__link, .SiteNav__item, .OffCanvas__phone, .OffCanvas__link, .SiteHeader__link, .SiteHeader__phone {
  line-height: 1.2;
  transition-property: color, opacity;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  color: var(--link-color);
}
.ListPagination__link:hover, .PolicyList__value:hover, .EmailsList__value:hover, .PhonesList__value:hover, .VideoPlayer__link:hover, .LinkBack__link:hover, .SiteNav__item:hover, .OffCanvas__phone:hover, .OffCanvas__link:hover, .SiteHeader__link:hover, .SiteHeader__phone:hover, .ListPagination__link:focus, .PolicyList__value:focus, .EmailsList__value:focus, .PhonesList__value:focus, .VideoPlayer__link:focus, .LinkBack__link:focus, .SiteNav__item:focus, .OffCanvas__phone:focus, .OffCanvas__link:focus, .SiteHeader__link:focus, .SiteHeader__phone:focus, .ListPagination__link:active, .PolicyList__value:active, .EmailsList__value:active, .PhonesList__value:active, .VideoPlayer__link:active, .LinkBack__link:active, .SiteNav__item:active, .OffCanvas__phone:active, .OffCanvas__link:active, .SiteHeader__link:active, .SiteHeader__phone:active {
  text-decoration: none;
  color: var(--link-color);
  opacity: 0.6;
}
.PolicyList__value {
  font-size: 0.75rem;
}
.ListPagination__link, .EmailsList__value, .LinkBack__link, .SiteHeader__link, .SiteHeader__phone {
  font-size: 0.875rem;
}
.SiteNav__item {
  font-size: 1rem;
}
.PhonesList__value, .OffCanvas__phone, .OffCanvas__link {
  font-size: 1.25rem;
  line-height: 1.4;
}
.PolicyList__value, .EmailsList__value, .PhonesList__value {
  font-weight: 400;
}
.SiteNav__item, .SiteHeader__link {
  font-weight: 500;
}
.ListPagination__link, .LinkBack__link, .OffCanvas__phone, .OffCanvas__link, .SiteHeader__phone {
  font-weight: 700;
}
.EmailsList--contacts .EmailsList__value, .PhonesList--contacts .PhonesList__value, .LinkBack__link {
  --link-color: #231f20;
}
.PolicyList__value, .EmailsList--footer .EmailsList__value, .PhonesList--footer .PhonesList__value, .SiteNav__item, .SiteHeader__link, .SiteHeader__phone {
  --link-color: #ffffff;
}
.OffCanvas__phone, .OffCanvas__link {
  --link-color: #028c84;
}
.ListPagination__item {
  --link-color: #471045;
}
.PolicyList__value, .SiteForm__policy {
  text-decoration: underline;
}
.PolicyList__value:hover, .SiteForm__policy:hover {
  text-decoration: underline;
}
.SiteForm__input--file {
  position: absolute;
  width: 1px;
  height: 1px;
  opacity: 0;
  z-index: -1;
}

.ListPagination__item, .ListPagination__link {
  display: flex;
  align-items: center;
  justify-content: center;
}

.EmailsList__caption, .PhonesList__caption {
  display: block;
  font-size: 0.875rem;
  line-height: 1.1;
  opacity: 0.6;
}

.Modal__close {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.2s ease-in-out;
}
.Modal__close:hover {
  background-color: rgba(71, 16, 69, 0.06);
}
.Modal__close:focus {
  background-color: rgba(206, 206, 206, 0.3);
}
.Modal__close:active {
  background-color: rgba(71, 16, 69, 0.15);
}

.ErrorPage {
  padding-top: 96px;
}
@media (max-width: 899px) {
  .ErrorPage {
    padding-top: 60px;
  }
}
@media (max-width: 639px) {
  .ErrorPage {
    padding-top: 40px;
  }
}

.Layout {
  display: flex;
  flex-flow: column;
  min-height: 100vh;
}
@media (max-width: 899px) {
  .Layout--contacts .Layout__footer {
    display: none;
  }
}
.Layout__header {
  z-index: 999;
  flex-shrink: 0;
}
.Layout__body {
  flex-grow: 1;
  padding-bottom: 160px;
}
@media (max-width: 899px) {
  .Layout__body {
    padding-bottom: 120px;
  }
}
@media (max-width: 639px) {
  .Layout__body {
    padding-bottom: 80px;
  }
}
.Layout__body--centerVertically {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}
.Layout__body--withBackground {
  background-image: url("/themes/frontend/assets/images/background.svg");
  background-position-y: 0;
}
.Layout__body--withErrorBackground {
  background-image: url("/themes/frontend/assets/images/error-background.svg");
  background-repeat: no-repeat;
  background-position: center top 43px;
}
.Layout__body--fullSize {
  padding-bottom: 0;
}
.Layout__body--dark {
  background-color: #f9f8f9;
}
@media (min-width: 1000px) {
  .Layout__body--map {
    overflow: hidden;
  }
}
@media (max-width: 999px) {
  .Layout__body--map {
    height: 80vh;
    padding-bottom: 0;
  }
}
@media (max-width: 899px) {
  .Layout__body--map {
    margin-top: -88px;
  }
}
.Layout__footer {
  flex-shrink: 0;
  background: linear-gradient(180deg, #00aea4 0%, #028c84 100%);
  position: relative;
  overflow: hidden;
}
.Layout__footer::after {
  content: "";
  display: block;
  width: 300px;
  height: 300px;
  position: absolute;
  z-index: 1;
  right: -60px;
  top: 20px;
  background-image: url("../../assets/icons/sdc-round.svg");
  background-repeat: no-repeat;
  background-size: 297px 300px;
  background-position: 0 0;
  clip-path: polygon(30% 0, 100% 0, 100% 100%, 30% 100%);
}
@media (max-width: 1199px) {
  .Layout__footer::after {
    display: none;
  }
}
.SiteHeader__homeLogoCell, .SiteHeader__logoCell {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  border: 1px solid rgba(35, 31, 32, 0.11);
}

@media (max-width: 1199px) {
  .SiteHeader__link, .SiteHeader__scheduleText, .SiteHeader__phone {
    font-size: 0.8rem;
  }
}

.SiteHeader {
  height: 160px;
}
@media (max-width: 899px) {
  .SiteHeader {
    height: 184px;
  }
}
.SiteHeader__background {
  position: absolute;
  width: 100%;
  z-index: -1;
}
.SiteHeader__background::before, .SiteHeader__background::after {
  content: "";
  display: block;
}
@media (max-width: 899px) {
  .SiteHeader__background::before, .SiteHeader__background::after {
    display: none;
  }
}
.SiteHeader__background::before {
  height: 48px;
  background-color: #028c84;
}
.SiteHeader__background::after {
  height: 112px;
  border-bottom: 1px solid rgba(35, 31, 32, 0.11);
}
.SiteHeader__container {
  padding-x: 0;
}
@media (max-width: 899px) {
  .SiteHeader__container {
    padding-top: 24px;
  }
}
.SiteHeader__grid {
  --logo-width: 155px;
  display: grid;
  grid-template-rows: 48px 112px;
  grid-template-columns: repeat(2, var(--logo-width)) 1fr;
}
@media (max-width: 1199px) {
  .SiteHeader__grid {
    --logo-width: 145px;
  }
}
@media (max-width: 899px) {
  .SiteHeader__grid {
    grid-template: 48px 120px/196px 1fr;
    grid-row-gap: 12px;
  }
}
.SiteHeader__logoCell {
  grid-column: 2;
  grid-row: 1/-1;
}
@media (max-width: 899px) {
  .SiteHeader__logoCell {
    grid-column: 1;
    grid-row: 1/2;
    border: none;
    padding-left: 30px;
    justify-content: flex-start;
  }
}
@media (max-width: 639px) {
  .SiteHeader__logoCell {
    padding-left: 15px;
  }
}
.SiteHeader__homeLogoCell {
  grid-column: 1;
  grid-row: 1/-1;
}
@media (max-width: 899px) {
  .SiteHeader__homeLogoCell {
    display: none;
  }
}
@media (max-width: 899px) {
  .SiteHeader__logo {
    width: 165px;
    height: 64px;
  }
}
.SiteHeader__topCell {
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-column: 3;
  padding-left: 1em;
}
@media (max-width: 1199px) {
  .SiteHeader__topCell {
    padding-left: 16px;
    padding-right: 16px;
  }
}
@media (max-width: 899px) {
  .SiteHeader__topCell {
    grid-column: 2/3;
    padding-right: 30px;
  }
}
.SiteHeader__topCellLeft {
  display: flex;
  align-items: center;
}
.SiteHeader__topCellRight {
  display: flex;
  align-items: center;
  gap: 0.6em;
}
@media (max-width: 899px) {
  .SiteHeader__topCellRight {
    display: none;
  }
}
.SiteHeader__navCell {
  grid-column: 3;
}
@media (max-width: 899px) {
  .SiteHeader__navCell {
    grid-column: 1/3;
  }
}
.SiteHeader__phoneWrapper {
  display: flex;
  align-items: center;
}
@media (max-width: 1199px) {
  .SiteHeader__phoneWrapper {
    display: none;
  }
}
.SiteHeader__phoneWrapper svg {
  width: 8px;
}
.SiteHeader__phoneIcon {
  margin-right: 0.5em;
}
.SiteHeader__phone {
  white-space: nowrap;
}
.SiteHeader__schedule {
  display: flex;
  align-items: center;
  background: none;
  border: none;
  color: #ffffff;
  font-size: 0.875rem;
  line-height: 1.2;
  text-decoration: dashed;
  outline: none;
  transition: opacity 0.2s ease-in-out;
}
@media (max-width: 899px) {
  .SiteHeader__schedule {
    display: none;
  }
}
.SiteHeader__schedule.uk-open {
  opacity: 0.6;
}
.SiteHeader__scheduleIcon {
  margin-right: 0.5em;
}
.SiteHeader__scheduleText {
  border-bottom: 1px dashed #ffffff;
}
.SiteHeader__scheduleDrop {
  display: none;
}
.SiteHeader__scheduleDropInner {
  padding: 24px;
  background-color: #ffffff;
  box-shadow: 0 4px 40px rgba(0, 51, 48, 0.15);
  border-radius: 10px;
}
.SiteHeader__timeWork:not(:last-child) {
  margin-bottom: 16px;
}
.SiteHeader__nav {
  height: 100%;
}
@media (max-width: 899px) {
  .SiteHeader__nav {
    overflow-x: auto;
    overflow-y: hidden;
    padding-y: 20px;
  }
}
.SiteHeader__mobile {
  position: absolute;
  right: 30px;
  top: 27px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
}
@media (min-width: 900px) {
  .SiteHeader__mobile {
    display: none;
  }
}
@media (max-width: 639px) {
  .SiteHeader__mobile {
    right: 15px;
  }
}
.SiteHeader__search {
  width: 10em;
}
@media (max-width: 1199px) {
  .SiteHeader__search {
    width: 7em;
  }
}
.SiteHeader__search--offcanvas {
  width: 100%;
}
.SiteHeader__search--offcanvas > .uk-search-icon {
  width: 40px;
}
.SiteHeader__search--offcanvas > .uk-search-icon > svg {
  fill: #265956;
}
.SiteHeader__search--offcanvas > .uk-search-input {
  height: 45px;
  color: rgba(38, 89, 86, 0.6);
}
html:root:root .SiteHeader__search--offcanvas > .uk-search-input {
  padding-left: 40px;
  background: rgba(38, 89, 86, 0.1);
}
:root .SiteHeader__search--offcanvas > .uk-search-input::placeholder {
  color: rgba(38, 89, 86, 0.6);
}

.HeaderNav__list {
  display: flex;
  align-items: stretch;
  height: 100%;
}
@media (max-width: 899px) {
  .HeaderNav__list {
    min-width: min-content;
    padding-x: 30px;
  }
}
@media (max-width: 639px) {
  .HeaderNav__list {
    padding-x: 15px;
  }
}
.HeaderNav__item {
  flex-grow: 1;
  flex-basis: 0;
  border-right: 1px solid rgba(35, 31, 32, 0.11);
  border-bottom: 1px solid rgba(35, 31, 32, 0.11);
  background-color: #ffffff;
}
@media (max-width: 899px) {
  .HeaderNav__item {
    border-right: 0;
    border-bottom: 0;
    border-radius: 10px;
    box-shadow: 0 4px 20px rgba(71, 16, 69, 0.1);
    min-width: 112px;
  }
}
@media (max-width: 899px) {
  .HeaderNav__item:not(:last-child) {
    margin-right: 8px;
  }
}
@media (max-width: 1199px) {
  .HeaderNav__item:last-child {
    border-right: 0;
  }
}
.HeaderNav__link {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1em;
}
@media (max-width: 899px) {
  .HeaderNav__link {
    letter-spacing: -0.01em;
    gap: 0.75em;
  }
}
.HeaderNav__link:hover .HeaderNav__icon svg .fill {
  fill: #bd2056;
}
.HeaderNav__link:hover .HeaderNav__icon svg .stroke {
  stroke: #bd2056;
}
.HeaderNav__link:hover .HeaderNav__caption {
  color: #bd2056;
}
.HeaderNav__caption {
  font-size: 1rem;
  line-height: 1.25;
  font-weight: 500;
  color: #265956;
  transition: color 0.2s ease-in-out;
}
@media (max-width: 899px) {
  .HeaderNav__caption {
    font-size: 0.875rem;
  }
}
.HeaderNav__icon {
  display: flex;
  align-items: center;
  height: 38px;
}
@media (max-width: 899px) {
  .HeaderNav__icon {
    height: 30px;
  }
}
.HeaderNav__icon svg {
  height: 100%;
}
.HeaderNav__icon svg .fill {
  transition: fill 0.2s ease-in-out;
}
.HeaderNav__icon svg .stroke {
  transition: stroke 0.2s ease-in-out;
}
.HeaderNav--offcanvas {
  margin-bottom: 32px;
}
.HeaderNav--offcanvas .HeaderNav__list {
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
}
.HeaderNav--offcanvas .HeaderNav__item {
  box-shadow: none;
  min-width: auto;
  padding: 16px 24px;
  border-bottom: 1px solid rgba(35, 31, 32, 0.1);
  width: 100%;
  border-radius: 0;
}
@media (max-width: 639px) {
  .HeaderNav--offcanvas .HeaderNav__item {
    padding-x: 16px;
  }
}
.HeaderNav--offcanvas .HeaderNav__item:first-child {
  border-top: 1px solid rgba(35, 31, 32, 0.1);
}
.HeaderNav--offcanvas .HeaderNav__link {
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.HeaderNav--offcanvas .HeaderNav__icon {
  justify-content: center;
  width: 30px;
  margin-bottom: 0;
  margin-right: 16px;
}

@media (min-width: 900px) {
  .HeaderNavHomeItem {
    display: none;
  }
}

.MobileBurger {
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  padding: 0;
  border: none;
  background-color: #028c84;
  outline: none;
  z-index: 1002;
  border-radius: 50%;
}
.MobileBurger::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  size: calc(100% + 8px);
  border: 1px dashed #028c84;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  transition: transform 0.25s ease-in-out, background-color 0.25s ease-in-out;
}
.MobileBurger__outer {
  position: relative;
  display: inline-block;
  width: 16px;
  height: 8px;
}
.MobileBurger__inner {
  top: 0;
  display: block;
}
.MobileBurger__inner, .MobileBurger__inner::after {
  position: absolute;
  width: 100%;
  height: 2px;
  transition: background-color 0.25s ease-in-out, transform 0.15s ease, top 75ms ease 0.12s;
  background-color: #ffffff;
}
.MobileBurger__inner::after {
  display: block;
  content: "";
}
.MobileBurger__inner::after {
  top: auto;
  bottom: -6px;
  transition: background-color 0.25s ease-in-out, bottom 75ms ease 0.12s, transform 75ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.MobileBurger[data-open=true] {
  background-color: #ffffff;
}
.MobileBurger[data-open=true]::before {
  transform: translate(-50%, -50%) rotate(45deg);
  border-color: #ffffff;
}
.MobileBurger[data-open=true] .MobileBurger__inner {
  background-color: #028c84;
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  transform: rotate(45deg);
  top: 3px;
}
.MobileBurger[data-open=true] .MobileBurger__inner::after {
  background-color: #028c84;
  bottom: 0;
  transition: background-color 0.25s ease-in-out, bottom 75ms ease, transform 75ms cubic-bezier(0.215, 0.61, 0.355, 1) 0.12s;
  transform: rotate(90deg);
}

.OffCanvas__search, .OffCanvas__homeContainer {
  padding: 1em;
}

.OffCanvas__timeWorkWrapper, .OffCanvas__phoneWrapper {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}

.OffCanvas {
  display: flex;
  flex-direction: column;
  max-width: 100vw;
  padding: 0;
}
.OffCanvas__logoWrapper, .OffCanvas__linkList, .OffCanvas__bottom {
  padding-x: 24px;
}
@media (max-width: 639px) {
  .OffCanvas__logoWrapper, .OffCanvas__linkList, .OffCanvas__bottom {
    padding-x: 16px;
  }
}
.OffCanvas__logoWrapper {
  background-color: #028c84;
  width: 100%;
  min-height: 100px;
  display: flex;
  align-items: center;
  padding-y: 24px;
}
@media (max-width: 639px) {
  .OffCanvas__logoWrapper {
    padding-y: 16px;
  }
}
.OffCanvas__logo {
  width: 166px;
}
.OffCanvas__bottom {
  margin-top: auto;
  padding-y: 32px;
  background-color: rgba(2, 140, 132, 0.05);
  color: #028c84;
}
.OffCanvas__phoneIcon, .OffCanvas__timeWorkIcon {
  margin-right: 14px;
  width: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.OffCanvas__timeWork, .OffCanvas__phone {
  font-size: 0.875rem;
  font-weight: 700;
}
.OffCanvas__partner {
  font-size: 0.875rem;
  font-weight: 500;
  margin-bottom: 8px;
  padding-left: 30px;
}
.OffCanvas__linkItem {
  margin-bottom: 24px;
}
.OffCanvas__link {
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.2;
}
.HomeWide {
  display: grid;
  padding: 1em;
  background: rgba(2, 141, 134, 0.05);
  color: inherit;
}
.HomeWide__text {
  grid-row: 1;
  grid-column: 1;
  justify-self: end;
  max-width: 7em;
  text-align: right;
  font-weight: 500;
}
.HomeWide__logo {
  grid-row: 1;
  grid-column: 1;
}

.SiteFooter {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: space-between;
  padding-y: 48px;
  color: #ffffff;
  column-gap: 70px;
  flex-wrap: wrap;
}
@media (max-width: 639px) {
  .SiteFooter {
    grid-template-columns: 1fr;
    padding-y: 24px;
  }
}
.SiteFooter__sdcLogoWrapper {
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 2;
  margin-bottom: 40px;
}
.SiteFooter__sdcLogo {
  width: 165px;
  height: 64px;
}
.SiteFooter__address {
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 2;
  grid-row-end: 3;
  margin-bottom: 18px;
  max-width: 360px;
  font-size: 1.25rem;
  line-height: 1.4;
}
@media (max-width: 639px) {
  .SiteFooter__address {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 3;
    grid-row-end: 4;
    max-width: 180px;
    margin-bottom: 34px;
  }
}
.SiteFooter__timeWork {
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 3;
  grid-row-end: 4;
  margin-bottom: 16px;
}
@media (max-width: 639px) {
  .SiteFooter__timeWork {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 4;
    grid-row-end: 5;
  }
}
.SiteFooter__partnersList {
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 4;
  grid-row-end: 5;
  margin-bottom: 40px;
}
@media (max-width: 639px) {
  .SiteFooter__partnersList {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 5;
    grid-row-end: 6;
  }
}
.SiteFooter__phonesList {
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 5;
  grid-row-end: 6;
  margin-bottom: 14px;
}
@media (max-width: 639px) {
  .SiteFooter__phonesList {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 7;
    grid-row-end: 8;
  }
}
.SiteFooter__emailsList {
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 6;
  grid-row-end: 7;
  margin-bottom: 48px;
}
@media (max-width: 639px) {
  .SiteFooter__emailsList {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 8;
    grid-row-end: 9;
    margin-bottom: 40px;
  }
}
.SiteFooter__proposalsList {
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 2;
  margin-top: 16px;
  margin-bottom: 40px;
}
@media (max-width: 999px) {
  .SiteFooter__proposalsList {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 3;
  }
}
@media (max-width: 639px) {
  .SiteFooter__proposalsList {
    display: none;
  }
}
.SiteFooter__container {
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 2;
  grid-row-end: 9;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}
@media (max-width: 999px) {
  .SiteFooter__container {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 3;
    grid-row-end: 9;
  }
}
@media (max-width: 639px) {
  .SiteFooter__container {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 9;
    grid-row-end: 10;
    margin-bottom: 24px;
  }
}
.SiteFooter__nav {
  margin-bottom: auto;
}
@media (max-width: 639px) {
  .SiteFooter__nav {
    display: none;
  }
}
.SiteFooter__socialsList {
  margin-top: 30px;
}
@media (max-width: 899px) {
  .SiteFooter__socialsList {
    margin-bottom: 70px;
  }
}
@media (max-width: 639px) {
  .SiteFooter__socialsList {
    margin: 0;
    align-self: flex-start;
  }
}
.SiteFooter__copyright {
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 7;
  grid-row-end: 8;
  display: block;
  margin-bottom: 16px;
  opacity: 0.6;
  font-size: 0.875rem;
  line-height: 1.28;
}
@media (max-width: 639px) {
  .SiteFooter__copyright {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 10;
    grid-row-end: 11;
    margin-bottom: 8px;
  }
}
.SiteFooter__policyList {
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 8;
  grid-row-end: 9;
  font-size: 0.875rem;
  line-height: 1.25;
}
@media (max-width: 639px) {
  .SiteFooter__policyList {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 11;
    grid-row-end: 12;
    margin-bottom: 16px;
    max-width: 300px;
  }
}
.SiteFooter__feipLogo {
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 7;
  grid-row-end: 9;
  margin-left: auto;
  align-self: flex-end;
  color: #ffffff;
  opacity: 0.3;
  transition: opacity 0.25s ease-in-out;
}
@media (max-width: 899px) {
  .SiteFooter__feipLogo {
    justify-self: flex-start;
  }
}
@media (max-width: 639px) {
  .SiteFooter__feipLogo {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 12;
    grid-row-end: 13;
    margin-left: 0;
  }
}
.SiteFooter__feipLogo:hover {
  opacity: 1;
  color: #ffffff;
}
.SiteFooter__feipLogo svg {
  fill: currentColor;
}

@media (max-width: 639px) {
  .SiteNav__listItem {
    text-align: center;
  }
}
.SiteNav__listItem:not(:last-child) {
  margin-bottom: 24px;
}
.SiteNav__item--active {
  pointer-events: none;
}

.LinkBack {
  position: relative;
  transition: color 0.2s ease-in-out;
}
.LinkBack__link:hover, .LinkBack__link:focus, .LinkBack__link:active {
  color: #bd2056;
  opacity: 1;
}
.LinkBack__link:hover .LinkBack__svg, .LinkBack__link:focus .LinkBack__svg, .LinkBack__link:active .LinkBack__svg {
  stroke: #bd2056;
}
.LinkBack__svg {
  transition: stroke 0.2s ease-in-out;
  margin-right: 16px;
  top: -2px;
  position: relative;
}

@media (max-width: 639px) {
  .VideoSection__container {
    padding: 0;
  }
}

.VideoPlayer {
  position: relative;
  width: 100%;
  max-width: 1200px;
  cursor: pointer;
  outline: none;
  padding-bottom: 56.25%;
  box-shadow: 0 30px 40px -30px rgba(27, 0, 26, 0.7);
  border-radius: 20px;
}
@media (max-width: 639px) {
  .VideoPlayer {
    border-radius: 0;
  }
}
.VideoPlayer--loaded .VideoPlayer__picture,
.VideoPlayer--loaded .VideoPlayer__playButton {
  opacity: 0;
  pointer-events: none;
}
.VideoPlayer--loading .VideoPlayer__playIcon {
  display: none;
}
.VideoPlayer--loading .VideoPlayer__spinner {
  display: block;
}
.VideoPlayer__preview {
  transition: opacity 0.25s ease-in-out;
  border-radius: 20px;
}
@media (max-width: 639px) {
  .VideoPlayer__preview {
    border-radius: 0;
  }
}
.VideoPlayer__preview, .VideoPlayer__player {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.VideoPlayer__player {
  z-index: 1;
  border-radius: 20px;
  background-color: rgba(35, 31, 32, 0.5);
}
@media (max-width: 639px) {
  .VideoPlayer__player {
    border-radius: 0;
  }
}
.VideoPlayer__player:hover .VideoPlayer__playIcon::before {
  background-color: rgba(255, 255, 255, 0.4);
}
.VideoPlayer__player:active .VideoPlayer__playIcon::before {
  background-color: rgba(255, 255, 255, 0.4);
}
.VideoPlayer__player:active .VideoPlayer__playIcon::after {
  border-left-color: #0c7973;
}
.VideoPlayer__playButton {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  width: 160px;
  height: 160px;
  padding: 0;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  border-radius: 50%;
  pointer-events: none;
  outline: none;
  transition: opacity 0.25s;
}
@media (max-width: 369px) {
  .VideoPlayer__playButton {
    width: 120px;
    height: 120px;
  }
}
.VideoPlayer__playButton::before, .VideoPlayer__playButton::after {
  content: "";
  display: block;
  position: absolute;
}
.VideoPlayer__playButton::before {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #028c84;
  filter: blur(30px);
  z-index: -1;
}
@media (max-width: 369px) {
  .VideoPlayer__playButton::before {
    width: 80%;
    height: 80%;
    top: 10%;
    left: 10%;
  }
}
.VideoPlayer__playButton::after {
  width: 60%;
  height: 60%;
  top: 20%;
  left: 20%;
  border: 1px dashed #ffffff;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0);
}
.VideoPlayer__playIcon {
  transition: transform 0.35s ease-out;
  display: block;
  position: relative;
  width: 80px;
  height: 80px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
}
.VideoPlayer__playIcon::before, .VideoPlayer__playIcon::after {
  content: "";
  display: block;
  position: absolute;
}
.VideoPlayer__playIcon::before {
  width: 80px;
  height: 80px;
  border: 1px solid #ffffff;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.VideoPlayer__playIcon::after {
  width: 0;
  height: 0;
  border-top: 14px solid transparent;
  border-bottom: 14px solid transparent;
  border-left: 24px solid #ffffff;
  top: 50%;
  left: 55%;
  transform: translate(-50%, -50%);
}
.VideoPlayer__spinner {
  display: none;
  color: white;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
  position: absolute;
}
.VideoPlayer__link:focus .VideoPlayer__playIcon::before {
  background-color: #0b635e;
}

.TimeWork {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  position: relative;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.28;
}
.TimeWork--bold {
  font-weight: 700;
}
.TimeWork__icon {
  margin-right: 18px;
}
.TimeWork__main {
  margin-right: 16px;
}

.PartnersList--tooltip .PartnersList__item, .PartnersList--footer .PartnersList__item {
  font-weight: 500;
}
.PartnersList--footer {
  opacity: 0.7;
}
.PartnersList__item {
  font-size: 0.875rem;
  line-height: 1.28;
  max-width: 320px;
}
@media (max-width: 639px) {
  .PartnersList__item {
    max-width: 100%;
  }
}
.PartnersList__item:not(:last-child) {
  margin-bottom: 8px;
}

.PhonesList__item:not(:last-child) {
  margin-bottom: 8px;
}
.EmailsList__item:not(:last-child) {
  margin-bottom: 14px;
}
.ProposalsList {
  display: grid;
  grid-template-columns: repeat(2, max-content);
}
@media (max-width: 899px) {
  .ProposalsList {
    grid-template-columns: 1fr;
  }
}
.ProposalsList--footer {
  grid-gap: 24px;
}
.ProposalsList--contacts {
  grid-gap: 16px;
}
.ProposalsList--contacts .ProposalsList__button {
  padding-x: 20px;
}
.ProposalsList--contacts .ProposalsList__button:hover {
  background-color: rgba(217, 244, 242, 0.5);
}
.ProposalsList--contacts .ProposalsList__button:focus {
  background-color: rgba(238, 238, 238, 0.5);
}
.ProposalsList--contacts .ProposalsList__button:active {
  background-color: rgba(209, 233, 231, 0.5);
}
.ProposalsList__button:hover, .ProposalsList__button:focus, .ProposalsList__button:active {
  background-color: rgba(255, 255, 255, 0.2);
}

.SocialsList {
  display: flex;
}
.SocialsList--footer .SocialsList__item {
  filter: drop-shadow(0px 8px 12px rgba(0, 0, 0, 0.15));
}
.SocialsList--contacts .SocialsList__item:not(:last-child), .SocialsList--shop .SocialsList__item:not(:last-child) {
  margin-right: 24px;
}
.SocialsList--contacts .SocialsList__icon, .SocialsList--shop .SocialsList__icon {
  fill: #231f20;
  transition: fill 0.2s ease-in-out;
}
.SocialsList--contacts .SocialsList__icon:hover, .SocialsList--shop .SocialsList__icon:hover {
  fill: #028c84;
  opacity: 1;
}
.SocialsList--contacts .SocialsList__link:focus .SocialsList__icon, .SocialsList--shop .SocialsList__link:focus .SocialsList__icon {
  fill: #028c84;
}
.SocialsList__item {
  size: 32px;
}
.SocialsList__item:not(:last-child) {
  margin-right: 32px;
}
@media (max-width: 424px) {
  .SocialsList__item:last-child {
    margin-right: 0;
  }
}
.SocialsList__icon {
  transition: opacity 0.2s ease-in-out;
}
.SocialsList__icon:hover {
  opacity: 0.6;
}

.PolicyList {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  opacity: 0.8;
}
.PolicyList__item:first-child {
  margin-right: 16px;
}
.PolicyList__item:not(:last-child) {
  margin-bottom: 8px;
}
.ListPagination {
  padding-top: 64px;
  display: flex;
  width: 100%;
  margin-bottom: 0;
}
@media (max-width: 639px) {
  .ListPagination {
    justify-content: center;
  }
}
@media (max-width: 899px) {
  .ListPagination {
    padding-top: 64px;
  }
}
@media (max-width: 424px) {
  .ListPagination {
    padding-top: 56px;
  }
}
.ListPagination--left {
  justify-content: flex-start;
}
.ListPagination--right {
  justify-content: flex-end;
}
.ListPagination--center {
  justify-content: center;
}
.ListPagination__mobileSelect {
  line-height: 56px;
  padding: 0 21px 0 0;
  appearance: none;
  text-align: center;
  text-align-last: center;
  outline: none;
  background-color: white;
}
@media (max-width: 369px) {
  .ListPagination__mobileSelect {
    margin: 0;
  }
}
.ListPagination__link {
  size: 100%;
  padding-top: 3px;
  line-height: 1.28;
  color: inherit;
  border-radius: 50%;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
}
.ListPagination__link:hover, .ListPagination__link:focus, .ListPagination__link:active {
  opacity: 1;
}
.ListPagination__link:focus {
  background-color: #e9e9e9;
}
.ListPagination__link:active {
  background-color: rgba(71, 16, 69, 0.5);
  color: #ffffff;
}
.ListPagination__item {
  margin: 0 4px;
  height: 48px;
  min-width: 48px;
}
@media (max-width: 424px) {
  .ListPagination__item {
    height: 56px;
    min-width: 56px;
  }
}
.ListPagination__item:first-child {
  margin-right: 48px;
}
@media (max-width: 424px) {
  .ListPagination__item:first-child {
    margin-right: 24px;
  }
}
.ListPagination__item:last-child {
  margin-left: 48px;
}
@media (max-width: 424px) {
  .ListPagination__item:last-child {
    margin-left: 24px;
  }
}
.ListPagination__item--desktop {
  transition: background-color 0.2s ease-in-out;
}
@media (max-width: 639px) {
  .ListPagination__item--desktop {
    display: none;
  }
}
.ListPagination__item--mobile {
  width: 115px;
  position: relative;
}
@media (min-width: 640px) {
  .ListPagination__item--mobile {
    display: none;
  }
}
.ListPagination__item--mobile::after {
  content: "";
  position: absolute;
  top: calc(50% - 7px / 2);
  right: 0;
  pointer-events: none;
  width: 0;
  height: 0;
  opacity: 0.3;
  margin-left: 10px;
  border: 7px solid transparent;
  border-top-color: #231f20;
}
.ListPagination__item--current {
  box-shadow: none;
  pointer-events: none;
}
.ListPagination__item--disabled {
  opacity: 0.5;
  pointer-events: none;
}
.ListPagination__item--prev, .ListPagination__item--next {
  padding: 0;
}
.ListPagination__item--prev .ListPagination__link, .ListPagination__item--next .ListPagination__link {
  padding-top: 0;
  color: inherit;
}
.ListPagination__item--prev .ListPagination__link:hover, .ListPagination__item--prev .ListPagination__link:active, .ListPagination__item--next .ListPagination__link:hover, .ListPagination__item--next .ListPagination__link:active {
  background-color: transparent;
}
.ListPagination__item--prev .ListPagination__link:focus, .ListPagination__item--next .ListPagination__link:focus {
  background-color: rgba(233, 233, 233, 0.3);
}
.ListPagination__item--current, .ListPagination__item--disabled, .ListPagination__item--prev, .ListPagination__item--next {
  box-shadow: none;
}
@media (hover: hover) {
  .ListPagination__item--current:hover, .ListPagination__item--current:focus, .ListPagination__item--disabled:hover, .ListPagination__item--disabled:focus, .ListPagination__item--prev:hover, .ListPagination__item--prev:focus, .ListPagination__item--next:hover, .ListPagination__item--next:focus {
    box-shadow: none;
  }
}
.ListPagination__item svg {
  stroke: currentColor;
  height: 14px;
}
@media (max-width: 424px) {
  .ListPagination__item svg {
    height: 30px;
    width: 10px;
  }
}

.ErrorPage {
  padding-bottom: 112px;
  width: 100%;
}
@media (max-width: 899px) {
  .ErrorPage {
    padding-bottom: 72px;
  }
}
@media (max-width: 639px) {
  .ErrorPage {
    padding-bottom: 56px;
  }
}
.ErrorPage__content {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.ErrorPage__title {
  margin-bottom: 48px;
}
@media (max-width: 639px) {
  .ErrorPage__title {
    margin-bottom: 24px;
  }
}
.ErrorPage__description {
  margin-bottom: 56px;
}
@media (max-width: 639px) {
  .ErrorPage__description {
    margin-bottom: 32px;
  }
}
.ErrorPage__code {
  font-weight: 700;
  font-size: 9rem;
  line-height: 100%;
  color: white;
  text-shadow: 0px 4px 40px rgba(71, 16, 69, 0.25);
  margin-bottom: 8px;
  text-align: center;
}
@media (max-width: 639px) {
  .ErrorPage__code {
    width: 100%;
    font-size: 6.25rem;
    margin-bottom: 16px;
  }
}
@media (max-width: 639px) {
  .ErrorPage__homeButton {
    width: 100%;
  }
}
@media (max-width: 369px) {
  .ErrorPage__homeButton {
    font-size: 1.125rem;
  }
}