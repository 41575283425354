$cell-border: 1px solid rgba(35, 31, 32, 0.11);

%logo {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $color-white;
    border: $cell-border;
}

%small-text-at-x-large-down {
    @include vw-xlarge-down {
        font-size: 0.8rem;
    }
}

.SiteHeader {
    $nav-padding: 20px;
    height: 160px;

    @include vw-medium-down {
        height: 184px;
    }

    &__background {
        position: absolute;
        width: 100%;
        z-index: -1;

        &::before,
        &::after {
            content: "";
            display: block;

            @include vw-medium-down {
                display: none;
            }
        }

        &::before {
            height: 48px;
            background-color: $color-new-green;
        }

        &::after {
            height: 112px;
            border-bottom: $cell-border;
        }
    }

    &__container {
        @extend .uk-container;
        padding-x: 0;

        @include vw-medium-down {
            padding-top: 24px;
        }
    }

    &__grid {
        --logo-width: 155px;

        display: grid;
        grid-template-rows: 48px 112px;
        grid-template-columns: repeat(2, var(--logo-width)) 1fr;

        @include vw-xlarge-down {
            --logo-width: 145px;
        }

        @include vw-medium-down {
            $logo-cell-width: (166px + $container-padding-horizontal-s);
            $nav-cell-height: 80px + $nav-padding * 2;
            grid-template: 48px #{$nav-cell-height} / #{$logo-cell-width} 1fr;
            grid-row-gap: (32px - $nav-padding);
        }
    }

    &__logoCell {
        @extend %logo;

        grid-column: 2;
        grid-row: 1 / -1;

        @include vw-medium-down {
            grid-column: 1;
            grid-row: 1 / 2;
            border: none;
            padding-left: $container-padding-horizontal-s;
            justify-content: flex-start;
        }

        @include vw-small-down {
            padding-left: $container-padding-horizontal;
        }
    }

    &__homeLogoCell {
        @extend %logo;

        grid-column: 1;
        grid-row: 1 / -1;

        @include vw-medium-down {
            display: none;
        }
    }

    &__logo {
        @include vw-medium-down {
            width: 165px;
            height: 64px;
        }
    }

    &__topCell {
        display: flex;
        align-items: center;
        justify-content: space-between;
        grid-column: 3;
        padding-left: 1em;

        @include vw-xlarge-down {
            padding-left: 16px;
            padding-right: 16px;
        }

        @include vw-medium-down {
            grid-column: 2 / 3;
            padding-right: $container-padding-horizontal-s;
        }
    }

    &__topCellLeft {
        display: flex;
        align-items: center;
    }

    &__topCellRight {
        display: flex;
        align-items: center;
        gap: 0.6em;

        @include vw-medium-down {
            display: none;
        }
    }

    &__navCell {
        grid-column: 3;

        @include vw-medium-down {
            grid-column: 1 / 3;
        }
    }

    &__phoneWrapper {
        display: flex;
        align-items: center;

        @include vw-xlarge-down {
            display: none;
        }

        svg {
            width: 8px;
        }
    }

    &__phoneIcon {
        margin-right: 0.5em;
    }

    &__phone {
        @extend %link, %link-s, %link-bold, %link-white, %small-text-at-x-large-down;

        white-space: nowrap;
    }

    &__schedule {
        display: flex;
        align-items: center;
        background: none;
        border: none;
        color: $color-white;
        font-size: toRem(14px);
        line-height: 1.2;
        text-decoration: dashed;
        outline: none;
        transition: opacity 0.2s ease-in-out;

        @include vw-medium-down {
            display: none;
        }

        &.uk-open {
            opacity: 0.6;
        }
    }

    &__scheduleIcon {
        margin-right: 0.5em;
    }

    &__scheduleText {
        @extend %small-text-at-x-large-down;

        border-bottom: 1px dashed $color-white;
    }

    &__scheduleDrop {
        display: none;
    }

    &__scheduleDropInner {
        padding: 24px;
        background-color: $color-white;
        box-shadow: 0 4px 40px rgba(0, 51, 48, 0.15);
        border-radius: 10px;
    }

    &__timeWork {
        &:not(:last-child) {
            margin-bottom: 16px;
        }
    }

    &__link {
        @extend %link, %link-s, %link-medium, %link-white, %small-text-at-x-large-down;
    }

    &__nav {
        height: 100%;

        @include vw-medium-down {
            overflow-x: auto;
            overflow-y: hidden;
            padding-y: $nav-padding;
        }
    }

    &__mobile {
        position: absolute;
        right: $container-padding-horizontal-s;
        top: 27px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 48px;
        height: 48px;

        @include vw-medium-up {
            display: none;
        }

        @include vw-small-down {
            right: $container-padding-horizontal;
        }
    }

    &__search {
        width: 10em;

        @include vw-xlarge-down {
            width: 7em;
        }

        &--offcanvas {
            width: 100%;

            & > .uk-search-icon {
                width: 40px;

                & > svg {
                    fill: #265956;
                }
            }

            & > .uk-search-input {
                height: 45px;
                color: rgba(#265956, 0.6);

                html:root:root & {
                    padding-left: 40px;
                    background: rgba(38, 89, 86, 0.1);
                }

                &::placeholder {
                    @at-root :root & {
                        color: rgba(#265956, 0.6);
                    }
                }
            }
        }
    }
}

.HeaderNav {
    $block: &;
    $cell-border: 1px solid rgba(35, 31, 32, 0.11);

    &__list {
        display: flex;
        align-items: stretch;
        height: 100%;

        @include vw-medium-down {
            min-width: min-content;
            padding-x: $container-padding-horizontal-s;
        }

        @include vw-small-down {
            padding-x: $container-padding-horizontal;
        }
    }

    &__item {
        flex-grow: 1;
        flex-basis: 0;
        border-right: $cell-border;
        border-bottom: $cell-border;
        background-color: $color-white;

        @include vw-medium-down {
            border-right: 0;
            border-bottom: 0;
            border-radius: 10px;
            box-shadow: 0 4px 20px rgba(71, 16, 69, 0.1);
            min-width: 112px;
        }

        &:not(:last-child) {
            @include vw-medium-down {
                margin-right: 8px;
            }
        }

        &:last-child {
            @include vw-xlarge-down {
                border-right: 0;
            }
        }
    }

    &__link {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1em;

        @include vw-medium-down {
            letter-spacing: -0.01em;
            gap: 0.75em;
        }

        &:hover {
            #{$block}__icon {
                svg {
                    .fill {
                        fill: $color-dark-pink;
                    }

                    .stroke {
                        stroke: $color-dark-pink;
                    }
                }
            }

            #{$block}__caption {
                color: $color-dark-pink;
            }
        }
    }

    &__caption {
        font-size: toRem(16px);
        line-height: 1.25;
        font-weight: $font-weight-medium;
        color: #265956;
        transition: color 0.2s ease-in-out;

        @include vw-medium-down {
            font-size: toRem(14px);
        }
    }

    &__icon {
        display: flex;
        align-items: center;
        height: 38px;

        @include vw-medium-down {
            height: 30px;
        }

        svg {
            height: 100%;

            .fill {
                transition: fill 0.2s ease-in-out;
            }

            .stroke {
                transition: stroke 0.2s ease-in-out;
            }
        }
    }

    &--offcanvas {
        margin-bottom: 32px;

        #{$block}__list {
            flex-direction: column;
            align-items: flex-start;
            padding: 0;
        }

        #{$block}__item {
            box-shadow: none;
            min-width: auto;
            padding: 16px 24px;
            border-bottom: 1px solid rgba($color-black, 0.1);
            width: 100%;
            border-radius: 0;

            @include vw-small-down {
                padding-x: 16px;
            }

            &:first-child {
                border-top: 1px solid rgba($color-black, 0.1);
            }
        }

        #{$block}__link {
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
        }

        #{$block}__icon {
            justify-content: center;
            width: 30px;
            margin-bottom: 0;
            margin-right: 16px;
        }
    }
}

.HeaderNavHomeItem {
    @include vw-medium-up {
        display: none;
    }
}

.MobileBurger {
    $block: &;
    $default-color: $color-white;
    $active-color: $color-new-green;
    $z-index: $z-index-header-burger;

    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    padding: 0;
    border: none;
    background-color: $active-color;
    outline: none;
    z-index: $z-index; // show over mobileBar
    border-radius: 50%;

    &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        display: block;
        size: calc(100% + 8px);
        border: 1px dashed $active-color;
        border-radius: 50%;
        transform: translate(-50%, -50%);
        transition: transform 0.25s ease-in-out, background-color 0.25s ease-in-out;
    }

    &__outer {
        position: relative;
        display: inline-block;
        width: 16px;
        height: 8px;
    }

    &__inner {
        top: 0;
        display: block;

        &,
        &::after {
            position: absolute;
            width: 100%;
            height: 2px;
            transition: background-color 0.25s ease-in-out, transform 0.15s ease, top 75ms ease 0.12s;
            background-color: $default-color;
        }

        &::after {
            display: block;
            content: "";
        }

        &::after {
            top: auto;
            bottom: -6px;
            transition: background-color 0.25s ease-in-out, bottom 75ms ease 0.12s,
                transform 75ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
        }
    }

    // open menu
    &[data-open="true"] {
        background-color: $default-color;

        &::before {
            transform: translate(-50%, -50%) rotate(45deg);
            border-color: $default-color;
        }

        #{$block}__inner {
            background-color: $active-color;
            transition-delay: 0.12s;
            transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            transform: rotate(45deg);
            top: 3px;

            &::after {
                background-color: $active-color;
                bottom: 0;
                transition: background-color 0.25s ease-in-out, bottom 75ms ease,
                    transform 75ms cubic-bezier(0.215, 0.61, 0.355, 1) 0.12s;
                transform: rotate(90deg);
            }
        }
    }
}

%off-canvas-item-container {
    padding: 1em;
}

%off-canvas-contacts-row {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
}

.OffCanvas {
    display: flex;
    flex-direction: column;
    max-width: 100vw;
    padding: 0;

    &__logoWrapper,
    &__linkList,
    &__bottom {
        padding-x: 24px;

        @include vw-small-down {
            padding-x: 16px;
        }
    }

    &__logoWrapper {
        background-color: $color-new-green;
        width: 100%;
        min-height: 100px;
        display: flex;
        align-items: center;
        padding-y: 24px;

        @include vw-small-down {
            padding-y: 16px;
        }
    }

    &__logo {
        width: 166px;
    }

    &__homeContainer {
        @extend %off-canvas-item-container;
    }

    &__bottom {
        margin-top: auto;
        padding-y: 32px;
        background-color: rgba($color-new-green, 0.05);
        color: $color-new-green;
    }

    &__phoneWrapper {
        @extend %off-canvas-contacts-row;
    }

    &__timeWorkWrapper {
        @extend %off-canvas-contacts-row;
    }

    &__phoneIcon,
    &__timeWorkIcon {
        margin-right: 14px;
        width: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__timeWork,
    &__phone {
        font-size: toRem(14px);
        font-weight: $font-weight-bold;
    }

    &__partner {
        font-size: toRem(14px);
        font-weight: $font-weight-medium;
        margin-bottom: 8px;
        padding-left: 30px;
    }

    &__linkItem {
        margin-bottom: 24px;
    }

    &__phone,
    &__link {
        @extend %link, %link-l, %link-bold, %link-new-green;
    }

    &__link {
        font-weight: $font-weight-medium;
        font-size: toRem(14px);
        line-height: 1.2;
    }

    &__search {
        @extend %off-canvas-item-container;
    }
}

.HomeWide {
    display: grid;
    padding: 1em;
    background: hsla(177, 97%, 28%, 0.05);
    color: inherit;

    &__text {
        grid-row: 1;
        grid-column: 1;
        justify-self: end;
        max-width: 7em;
        text-align: right;
        font-weight: $font-weight-medium;
    }

    &__logo {
        grid-row: 1;
        grid-column: 1;
    }
}
