// Important reset styles !!!
$base-margin-vertical: 0;
$base-list-padding-left: 0;

// Base
$base-body-background: white;
$base-body-color: $color-black;
$base-heading-color: $color-black;
$container-max-width: 1136px;

// Fonts
$base-body-font-family: $font-family-base;
$base-heading-font-family: $font-family-headings;

// Links
//$base-link-color: inherit;
$base-link-text-decoration: none;
//$base-link-hover-color: blue
$base-link-hover-text-decoration: none;

// Buttons
$button-font-size: toRem(16px);
$button-line-height: toRem(48px);
$button-padding-horizontal: 32px;

$button-large-font-size: toRem(20px);
$button-large-line-height: toRem(64px);
$button-large-padding-horizontal: 40px;

$button-small-font-size: toRem(14px);
$button-small-line-height: toRem(32px);
$button-small-padding-horizontal: 16px;

// Modal
$modal-background: linear-gradient(180deg, rgba(0, 174, 164, 0.9) 0%, rgba(2, 140, 132, 0.9) 100%);
$modal-dialog-width: 880px;
$modal-body-padding-horizontal: 40px;
$modal-body-padding-vertical: 40px;

// Form
$form-height: 48px;
$form-background: transparent;
$form-focus-background: transparent;
$form-success-color: transparent;
$form-color: $color-dark-purple;
$form-focus-color: inherit;

// Search
$search-color: $color-white;
$search-default-height: 28px;
$search-default-background: #00766f;
$search-default-icon-width: 32px;
$search-default-focus-background: #00635d;

// Offcanvas
$offcanvas-bar-background: $color-white;
$offcanvas-overlay-background: linear-gradient(180deg, rgba(0, 174, 164, 0.9) 0%, rgba(2, 140, 132, 0.9) 100%);
$offcanvas-bar-width: 100%;
$offcanvas-bar-width-s: 400px;

// Drop
$drop-width: auto;

// Container
$container-large-max-width: 1278px;
