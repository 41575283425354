%large-padding-page {
    padding-top: 96px;

    @include vw-medium-down {
        padding-top: 60px;
    }

    @include vw-small-down {
        padding-top: 40px;
    }
}

%small-padding-page {
    padding-top: 46px;

    @include vw-small-down {
        padding-top: 40px;
    }
}
