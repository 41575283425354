@import 'utils';

.show-for-sr {
	position: absolute;
	top: 0;
	visibility: hidden;
	size: 1px;
}

.show-for-js {
	@at-root .no-js & {
		display: none;
	}
}

.hide-for-js {
	@at-root .js & {
		display: none;
	}
}

.responsiveEmbed {
	position: relative;
	padding: 0 0 aspect-ratio(16, 9);

	iframe {
		position: absolute;
		top: 0;
		left: 0;
		// override inline styles
		size: 100% !important;
	}
}
