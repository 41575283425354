.SiteForm {
    $block: &;
    border-radius: 10px;

    &__column:not(:last-child) {
        @include vw-small-down {
            margin-bottom: 12px;
        }
    }

    &__container {
        display: flex;
        align-items: center;

        @include vw-small-down {
            flex-wrap: wrap;
        }

        &--subscribe {
            justify-content: center;
            flex-wrap: wrap;
        }
    }

    &__block {
        &:not(:last-child) {
            margin-bottom: 16px;

            @include vw-xsmall-down {
                margin-bottom: 12px;
            }
        }

        &--subscribe:not(:last-child) {
            margin-bottom: 0;

            @include vw-small-down {
                margin-bottom: 12px;
            }
        }

        &--file {
            width: 100%;

            &:not(:last-child) {
                margin-bottom: 42px;
            }
        }

        &--heightExpand {
            height: 100%;
        }
    }

    &__field {
        display: flex;
        flex-wrap: wrap;
        height: inherit;

        &--file {
            align-items: center;
        }
    }

    &__grid {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 24px;

        @include vw-small-down {
            grid-template-columns: 1fr;
            grid-gap: 0;
        }

        &--3column {
            grid-template-columns: repeat(3, 1fr);

            @include vw-small-down {
                grid-template-columns: 1fr;
                grid-gap: 0;
            }
        }
    }

    &__heading {
        @extend %heading-medium;

        margin-bottom: 32px;

        @include vw-xxsmall-down {
            margin-bottom: 20px;
        }

        &--subscribe {
            width: 100%;
            text-align: center;
        }
    }

    &__motivation {
        max-width: 420px;
        text-align: center;
        margin-bottom: 32px;
    }

    &__icon {
        transition: opacity 0.4s ease-in-out, fill 0.4s ease-in-out;

        &--attach {
            order: -1;
            position: relative;
            top: -1px;
        }

        &--attachClose {
            fill: $color-dark-pink;

            @media (hover: hover) {
                &:hover {
                    cursor: pointer;
                    fill: $color-new-green;

                    & ~ #{$block}__label--file {
                        opacity: 0.6;
                    }
                }
            }
        }
    }

    &__label {
        font-size: toRem(14px);
        line-height: 1;
        font-weight: $font-weight-medium;
        order: -1;
        margin-bottom: 5px;
        transition: opacity 0.4s ease-in-out, color 0.4s ease-in-out, border-bottom-color 0.4s ease-in-out;

        @media (hover: hover) {
            &:hover {
                cursor: pointer;
                opacity: 0.8;
            }
        }

        &--file {
            color: $color-dark-pink;
            border-bottom: 1px dashed rgba($color-dark-pink, 0.5);
            margin: 0 12px 0 8px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            max-width: 290px;
            order: 0;

            &:hover {
                color: $color-new-green;
                border-color: $color-new-green;

                & + #{$block}__icon--attach {
                    fill: $color-new-green;
                }
            }

            &:hover {
                color: $color-new-green;
                border-color: $color-new-green;

                & + #{$block}__icon--attach {
                    fill: $color-new-green;
                }
            }
        }
    }

    &__input,
    &__textarea {
        &:required {
            & + #{$block}__label {
                &::after {
                    content: "*";

                    color: $color-dark-pink;
                }
            }
        }
    }

    &__input {
        @extend .uk-input;
        transition: border-color 0.4s ease-in-out;

        &--file {
            @extend %visually-hidden;

            &:active ~ #{$block}__label--file,
            &:focus ~ #{$block}__label--file {
                color: $color-new-green;
                border-color: $color-new-green;

                & + #{$block}__icon--attach {
                    fill: $color-new-green;
                }
            }
        }

        &--fileAttached ~ #{$block}__label--file {
            color: $color-black;
            border-bottom: none;
            max-width: calc(100% - 60px);

            @include vw-xsmall-down {
                width: calc(100% - 60px);
                max-width: 240px;
            }

            @include vw-xxsmall-down {
                max-width: 190px;
            }

            &:hover {
                color: $color-new-green;
                opacity: 1;
            }
        }

        &--fileAttached ~ #{$block}__icon--attach {
            fill: $color-black;
            opacity: 0.3;
        }

        &--fileAttached ~ #{$block}__buttonAttach {
            opacity: 1;
            z-index: 0;
        }

        &--invalid {
            border-color: $color-dark-pink;

            & ~ #{$block}__error {
                opacity: 0.8;
                display: block;
            }
        }
    }

    &__textarea {
        @extend .uk-textarea;

        resize: none;
        height: calc(100% - 19px);

        @include vw-small-down {
            height: 160px;
        }
    }

    &__bottom {
        display: flex;
        flex-wrap: wrap;
        grid-column: 1 / -1;
        margin-top: 32px;

        @include vw-xsmall-down {
            margin-top: 24px;
        }

        &--rent {
            margin-top: 41px;
        }
    }

    &__agreement {
        color: $color-dark-purple;
        font-size: toRem(12px);
        line-height: 1.4;
        text-align: left;
        opacity: 0.8;
        max-width: 500px;
    }

    &__buttonAttach {
        @extend .uk-button;

        opacity: 0;
        z-index: -1;
        order: 4;
        padding: 0;
        line-height: 1;
        background-color: transparent;

        &:focus,
        &:active,
        &:hover {
            & ~ #{$block}__label--file {
                opacity: 0.6;
            }

            & #{$block}__icon--attachClose {
                fill: $color-new-green;
            }
        }
    }

    &__buttonSend {
        @extend .uk-button, .uk-button-rounded, .uk-button-dark-pink;

        margin-right: 32px;
        padding-x: 40px;

        @include vw-small-down {
            margin-bottom: 12px;
        }

        &--subscribe {
            margin: 0;
        }
    }

    &__policy {
        @extend %link-underlined;

        color: inherit;

        &:hover,
        &:focus,
        &:active {
            color: $color-new-green;
        }
    }

    &__error {
        margin-top: 5px;
        color: $color-dark-pink;
        font-weight: $font-weight-medium;
        font-size: toRem(12px);
        line-height: 1;
        display: none;
        opacity: 0;
        transition: opacity 0.4s ease-in-out;
    }
}
