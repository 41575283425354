// This file contains all custom variables of this project

$root-font-size: 16px;

// Colors
$color-black: #231f20;
$color-dark-purple: #471045;
$color-light-pink: #ff7cb5;
$color-dark-pink: #bd2056;
$color-orange: #ff7000;
$color-yellow: #ffd300;
$color-green: #648c02;
$color-white: #ffffff;
$color-new-green: #028c84;
$color-gray: #dedede;
$color-grayish: #f2f9f9;

// Fonts
$font-family-base: "Geometria", sans-serif;
$font-family-headings: $font-family-base;

// Font weights
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;

// Headings
$font-size-heading-small: 20px;
$font-size-heading-medium: 32px;
$font-size-heading-large: 56px;

// Local variables
$slider-card-hover-translate: 16px;

// Layers
$z-index-header: 999;
$z-index-map-tooltip: $z-index-header + 1;
$z-index-offcanvas: $z-index-map-tooltip + 1;
$z-index-header-burger: $z-index-offcanvas + 1;
$z-index-catalog-nav-offcanvas: $z-index-header-burger + 1;

// Other
$map-view-height: 80vh;
