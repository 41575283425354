.Layout {
    $block: &;

    display: flex;
    flex-flow: column;
    min-height: 100vh;

    &--contacts {
        #{$block}__footer {
            @include vw-medium-down {
                display: none;
            }
        }
    }

    &__header {
        z-index: $z-index-header;
        flex-shrink: 0;
    }

    &__body {
        flex-grow: 1;
        padding-bottom: 160px;

        @include vw-medium-down {
            padding-bottom: 120px;
        }

        @include vw-small-down {
            padding-bottom: 80px;
        }

        &--centerVertically {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            position: relative;
        }

        &--withBackground {
            background-image: url("#{themePath('assets/images/background.svg')}");
            background-position-y: 0;
        }

        &--withErrorBackground {
            background-image: url("#{themePath('assets/images/error-background.svg')}");
            background-repeat: no-repeat;
            background-position: center top 43px;
        }

        &--fullSize {
            padding-bottom: 0;
        }

        &--dark {
            background-color: #f9f8f9;
        }

        &--map {
            @include vw-large-up {
                overflow: hidden;
            }

            @include vw-large-down {
                height: $map-view-height;
                padding-bottom: 0;
            }

            @include vw-medium-down {
                margin-top: -88px;
            }
        }
    }

    &__footer {
        $color-new-green-light: #00aea4;

        flex-shrink: 0;
        background: linear-gradient(180deg, $color-new-green-light 0%, $color-new-green 100%);
        position: relative;
        overflow: hidden;

        &::after {
            content: "";
            display: block;
            width: 300px;
            height: 300px;
            position: absolute;
            z-index: 1;
            right: -60px;
            top: 20px;
            background-image: url("../../assets/icons/sdc-round.svg");
            background-repeat: no-repeat;
            background-size: 297px 300px;
            background-position: 0 0;
            clip-path: polygon(30% 0, 100% 0, 100% 100%, 30% 100%);

            @include vw-xlarge-down {
                display: none;
            }
        }
    }

    &__container {
        @extend .uk-container;

        &--large {
            @extend .uk-container-large;
        }

        &--small {
            @extend .uk-container-small;
        }
    }
}
