@function aspect-ratio($width, $height) {
	@return (($height / $width) * 100%);
}

@mixin media-touch {
	@media (hover: none) and (pointer: coarse) {
		@content;
	}
}

@mixin media-no-touch {
	@media (hover: hover) and (pointer: fine) {
		@content;
	}
}
