.Modal {
    &__close {
        @extend .uk-button,
            .uk-button-transparent,
            .uk-button-round,
            .uk-button-border-dashed,
            .uk-button-border-dark-purple;

        display: flex;
        align-items: center;
        justify-content: center;
        transition: background-color 0.2s ease-in-out;

        &:hover {
            background-color: rgba($color-dark-purple, 0.06);
        }

        &:focus {
            background-color: rgba(#cecece, 0.3);
        }

        &:active {
            background-color: rgba($color-dark-purple, 0.15);
        }
    }
}
